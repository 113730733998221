import React from 'react';

import {getPhonemeDeletion} from 'constants/tests.js';

import AdminTestPanel from 'components/AdminTestPanel';
import strings from 'strings';
import AdminReadyButton from 'components/AdminReadyButton';
import StopWatch from 'components/StopWatch';
import CountDown from 'components/CountDown';

class PhonemeDeletionTestAdmin extends AdminTestPanel {
	constructor(props) {
		super(props, {
			enableTimers: false,
		});
		this.state = {
			...this.state,
			maxConsecutiveErrors: 5,
			stopOnConsecutiveErrors: true,
			key: 1,
		};
	}

	getTestName = () => {
		return strings.pd_testname;
	};
	updateTimeUsed = (timeUsed) => {
		let skipTime = this.state.skipTime;

		if (skipTime == timeUsed) {
			const newSkipTime = 30 + timeUsed;
			this.setState({
				timeElapsedLasAnsnwer: timeUsed,
				timeUsed: timeUsed,
				skipTime: newSkipTime,
			});
			this.answerWrong();
		} else
			this.setState({
				timeUsed: timeUsed,
				timeElapsedLasAnsnwer: timeUsed,
			});
	};
	handleKeyPress = (event) => {
		const {
			options: {limitHotkeysToTest, enableHotkeys},
			currentStage,
		} = this.state;

		if ((!limitHotkeysToTest || currentStage === 1) && enableHotkeys) {
			if (
				event.key === 'a' &&
				this.state.timeElapsedLasAnsnwer + 30 !== this.state.skipTime
			) {
				const skipTime = 30 + this.state.timeElapsedLasAnsnwer;
				this.setState({skipTime});
				this.answerCorrect();
				this.handleCountdownComplete();
			}
			if (
				event.key === 's' &&
				this.state.timeElapsedLasAnsnwer + 30 !== this.state.skipTime
			) {
				const skipTime = 30 + this.state.timeElapsedLasAnsnwer;
				this.setState({skipTime});
				this.answerWrong();
				this.handleCountdownComplete();
			}
			if (
				event.key === 'd' &&
				this.state.timeElapsedLasAnsnwer + 30 !== this.state.skipTime
			) {
				const skipTime = 30 + this.state.timeElapsedLasAnsnwer;
				this.setState({skipTime});
				this.answerUnanswered();
				this.handleCountdownComplete();
			}
		}
	};
	answerWrong = () => {
		const {
			consecutiveErrors,
			currentStep,
			options,
			maxConsecutiveErrors,
			stopOnConsecutiveErrors,
		} = this.state;
		const newResults = Object.assign({}, this.state.results, {
			[currentStep]: {
				isCorrect: false,
				isAnswered: true,
			},
		});
		this.setState(
			{
				wrongAnswers: this.state.wrongAnswers + 1,
				results: newResults,
				consecutiveErrors: consecutiveErrors + 1,
			},
			() => {
				const currErrors = this.state.consecutiveErrors;
				const maxErrors = maxConsecutiveErrors;
				if (currErrors >= maxErrors && stopOnConsecutiveErrors) {
					if (options.enableTimers) this.props.endTimer();
					this.props.submitResults(this.state.results);
					this.props.nextStage();
				} else {
					if (options.enableTimers) this.props.endTimer();
					this.props.nextStep(this.state.results);
				}
			}
		);
		if (this.answerCB) this.answerCB();
	};

	answerUnanswered = () => {
		const {
			options,
			consecutiveErrors,
			currentStep,
			maxConsecutiveErrors,
			stopOnConsecutiveErrors,
		} = this.state;

		const newResults = Object.assign({}, this.state.results, {
			[currentStep]: {
				isCorrect: false,
				isAnswered: false,
			},
		});

		this.setState(
			{
				unanswered: this.state.unanswered + 1,
				results: newResults,
				consecutiveErrors: consecutiveErrors + 1,
			},
			() => {
				const currErrors = this.state.consecutiveErrors;
				const maxErrors = maxConsecutiveErrors;
				if (currErrors >= maxErrors && stopOnConsecutiveErrors) {
					if (options.enableTimers) this.props.endTimer();
					this.props.submitResults(this.state.results);
					this.props.nextStage();
				} else {
					if (options.enableTimers) this.props.endTimer();
					this.props.nextStep(this.state.results);
				}
			}
		);
		if (this.answerCB) this.answerCB();
	};

	handleCountdownComplete = () => {
		this.setState((prevState) => ({
			key: prevState.key < 20 ? prevState.key + 1 : 1,
		}));
	};

	renderIntro = () => {
		const {currentStep} = this.state;
		const introSteps = getPhonemeDeletion().getIntroSteps();
		const isPracticeTask =
			currentStep > 0 && currentStep < introSteps.length - 1;
		return (
			<>
				<p>{strings.pd_admin1}</p>
				{currentStep === 0 && (
					<AdminReadyButton
						remoteIsReady={this.props.candidateReady}
						onClick={this.props.nextStep}
					/>
				)}
				{isPracticeTask && (
					<>
						{strings.formatString(
							strings.taskNum,
							introSteps[currentStep].task
						)}
						<br />
						{strings.formatString(
							strings.expectedAnswer,
							<b>{introSteps[currentStep].expectedAnswer}</b>
						)}
						<br />
						<button onClick={this.props.nextStep}>
							{strings.correctAnswerGiven}
						</button>
					</>
				)}
				{currentStep === 7 && (
					<AdminReadyButton
						remoteIsReady={this.props.candidateReady}
						onClick={this.props.nextStage}
					/>
				)}
			</>
		);
	};

	renderTest = () => {
		const {currentStep} = this.state;
		return (
			<div>
				<div className="lit-stop-watch-div">
					<StopWatch autoStart integer onTick={this.updateTimeUsed} />
				</div>
				<p>
					<br />
					{strings.pd_admin2}
					<br />
					{strings.hotkeyDescription}
					<br />
					{strings.hotkeyCorrect} <br /> {strings.hotkeyWrong}
				</p>
				<p>
					{strings.formatString(
						strings.taskNumOf,
						currentStep + 1,
						getPhonemeDeletion().getAnswers().length
					)}
				</p>
				<p>
					{strings.formatString(
						strings.taskNum,
						getPhonemeDeletion().getSteps()[currentStep]
					)}
					<br />
					{strings.formatString(
						strings.expectedAnswer,
						<b>{getPhonemeDeletion().getAnswers()[currentStep]}</b>
					)}
				</p>
				<div className="lit-all-buttons-div">
					<button
						disabled={
							this.state.timeElapsedLasAnsnwer + 30 ===
							this.state.skipTime
						}
						onClick={(e) => {
							e.stopPropagation();
							const skipTime =
								30 + this.state.timeElapsedLasAnsnwer;
							this.setState({skipTime});
							this.answerCorrect();
							this.handleCountdownComplete();
						}}
					>
						{strings.hotkeyButtonCorrect}
					</button>
					<button
						disabled={
							this.state.timeElapsedLasAnsnwer + 30 ===
							this.state.skipTime
						}
						onClick={(e) => {
							e.stopPropagation();
							const skipTime =
								30 + this.state.timeElapsedLasAnsnwer;
							this.setState({skipTime});
							this.answerWrong();
							this.handleCountdownComplete();
						}}
					>
						{strings.hotkeyButtonWrong}
					</button>
					<button
						disabled={
							this.state.timeElapsedLasAnsnwer + 30 ===
							this.state.skipTime
						}
						onClick={(e) => {
							e.stopPropagation();
							const skipTime =
								30 + this.state.timeElapsedLasAnsnwer;
							this.setState({skipTime});
							this.answerUnanswered();
							this.handleCountdownComplete();
						}}
					>
						{strings.hotkeyButtonUnanswered}
					</button>
					<div className="lit-count-down-div">
						{this.state.timeElapsedLasAnsnwer + 30 !==
							this.state.skipTime && (
							<CountDown
								key={this.state.key}
								duration={30}
								onComplete={this.handleCountdownComplete}
							/>
						)}
					</div>
				</div>
			</div>
		);
	};

	renderWaiting = () => {
		return (
			<div>
				<p>
					{strings.formatString(
						strings.testDone,
						strings.pd_testname
					)}
				</p>
				<button onClick={this.props.nextStage}>
					{strings.nextTest}
				</button>
			</div>
		);
	};
}

export default PhonemeDeletionTestAdmin;
