import React from 'react';

import {getRapidNaming} from 'constants/tests.js';

import StopWatch from 'components/StopWatch';

import AdminTestPanel from 'components/AdminTestPanel';
import AdminReadyButton from 'components/AdminReadyButton';

import strings from 'strings';
import grabamse from 'assets/images/rapidNaming/grabamse.png';
import gragenser from 'assets/images/rapidNaming/gragenser.png';
import graeple from 'assets/images/rapidNaming/graeple.png';
import gratommel from 'assets/images/rapidNaming/gratommel.png';
import grasykkel from 'assets/images/rapidNaming/grasykkel.png';

import blabamse from 'assets/images/rapidNaming/blabamse.png';
import blagenser from 'assets/images/rapidNaming/blagenser.png';
import blaeple from 'assets/images/rapidNaming/blaeple.png';
import blatommel from 'assets/images/rapidNaming/blatommel.png';
import blasykkel from 'assets/images/rapidNaming/blasykkel.png';

import oransjebamse from 'assets/images/rapidNaming/oransjebamse.png';
import oransjegenser from 'assets/images/rapidNaming/oransjegenser.png';
import oransjeeple from 'assets/images/rapidNaming/oransjeeple.png';
import oransjetommel from 'assets/images/rapidNaming/oransjetommel.png';
import oransjesykkel from 'assets/images/rapidNaming/oransjesykkel.png';

import rodbamse from 'assets/images/rapidNaming/rodbamse.png';
import rodgenser from 'assets/images/rapidNaming/rodgenser.png';
import rodeple from 'assets/images/rapidNaming/rodeple.png';
import rodtommel from 'assets/images/rapidNaming/rodtommel.png';
import rodsykkel from 'assets/images/rapidNaming/rodsykkel.png';

import gronnbamse from 'assets/images/rapidNaming/gronnbamse.png';
import gronngenser from 'assets/images/rapidNaming/gronngenser.png';
import gronneple from 'assets/images/rapidNaming/gronneple.png';
import gronntommel from 'assets/images/rapidNaming/gronntommel.png';
import gronnsykkel from 'assets/images/rapidNaming/gronnsykkel.png';

const images = {
	greyteddy: grabamse,
	greysweater: gragenser,
	greyapple: graeple,
	greythumb: gratommel,
	greybike: grasykkel,
	blueteddy: blabamse,
	bluesweater: blagenser,
	blueapple: blaeple,
	bluethumb: blatommel,
	bluebike: blasykkel,
	orangeteddy: oransjebamse,
	orangesweater: oransjegenser,
	orangeapple: oransjeeple,
	orangethumb: oransjetommel,
	orangebike: oransjesykkel,
	redteddy: rodbamse,
	redsweater: rodgenser,
	redapple: rodeple,
	redthumb: rodtommel,
	redbike: rodsykkel,
	greenteddy: gronnbamse,
	greensweater: gronngenser,
	greenapple: gronneple,
	greenthumb: gronntommel,
	greenbike: gronnsykkel,
};
const getImage = (color, item) => {
	if (images[color + item]) return images[color + item];
	console.error('Item ' + color + item + ' not found');
	return null;
};

class Admin extends AdminTestPanel {
	getTestName = () => {
		return strings.rn_testname;
	};

	updateTimeUsed = (timeUsed) => {
		this.setState({timeUsed: timeUsed});
	};

	renderIntro = () => {
		const {currentStep} = this.props;

		return (
			<div>
				<p>{strings.rn_admin1}</p>
				{currentStep === 0 && (
					<>
						<p>{strings.rn_admin2}</p>
						<button
							onClick={() => {
								this.props.nextStep();
							}}
						>
							{strings.startPracticeTasks}
						</button>
					</>
				)}
				{currentStep === 1 && (
					<>
						<p>
							{strings.admin3}
							<br />
							{strings.formatString(
								strings.expectedAnswer,
								<b>{strings.rn_colors}</b>
							)}
						</p>
						<p>{strings.rn_adjustIfNotCorrect}</p>
						<button
							onClick={() => {
								this.props.nextStep();
							}}
						>
							{strings.rn_correctColors}
						</button>
					</>
				)}
				{currentStep === 2 && (
					<>
						<p>
							{strings.rn_admin4}
							<br />
							{strings.formatString(
								strings.expectedAnswer,
								<b>{strings.rn_objects}</b>
							)}
						</p>
						<p>{strings.rn_adjustIfNotCorrect}</p>
						<button onClick={() => this.props.nextStep()}>
							{strings.rn_correctItems}
						</button>
					</>
				)}
				{currentStep === 3 && (
					<>
						<p>
							{strings.rn_admin5}
							<br />
							{strings.rn_admin6}
							<br />
							{strings.formatString(
								strings.expectedAnswer,
								<b>{strings.rn_objectsWithColors}</b>
							)}
						</p>
						<button onClick={this.props.nextStep}>
							{strings.rn_correctItems}
						</button>
					</>
				)}
				{currentStep === 4 && (
					<>
						<p>{strings.rn_admin7}</p>
						<p>{strings.clickStartWhenReady}</p>
						<AdminReadyButton
							remoteIsReady={this.props.candidateReady}
							onClick={this.props.nextStage}
						/>
					</>
				)}
			</div>
		);
	};

	renderTest = () => {
		const {currentStep} = this.state;
		const steps = getRapidNaming(this.props.ageGroup).getSteps();
		return (
			<div>
				<p>
					<StopWatch autoStart integer onTick={this.updateTimeUsed} />
					<br />
					{strings.rn_admin8}
					<br />
					{strings.rn_admin9}
					<br />
					{strings.hotkeyCorrect} <br /> {strings.hotkeyWrong}
				</p>
				<p>
					{strings.formatString(
						strings.taskNumOf,
						currentStep + 1,
						getRapidNaming(this.props.ageGroup).getSteps().length
					)}
				</p>
				<p>
					{strings.formatString(
						strings.expectedAnswer,
						<b>
							{strings[steps[currentStep].color]}{' '}
							{strings[steps[currentStep].item]}
						</b>
					)}
				</p>

				<p className={`adminItem`}>
					<img
						src={getImage(
							steps[currentStep].color,
							steps[currentStep].item
						)}
					/>
				</p>
				<button
					onClick={(e) => {
						e.stopPropagation();

						this.answerCorrect();
					}}
				>
					{strings.hotkeyButtonCorrect}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();

						this.answerWrong();
					}}
				>
					{strings.hotkeyButtonWrong}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerUnanswered();
					}}
				>
					{strings.hotkeyButtonUnanswered}
				</button>
			</div>
		);
	};

	renderWaiting = () => {
		return (
			<div>
				<p>
					{strings.formatString(
						strings.testDone,
						strings.rn_testname
					)}
				</p>
				<p>
					{strings.formatString(
						strings.rn_timeUsed,
						this.state.timeUsed
					)}
				</p>
				<button onClick={() => this.props.nextStage()}>
					{strings.nextTest}
				</button>
			</div>
		);
	};
	answerCB = () => {
		this.props.startTimer();
	};
}

export default Admin;
