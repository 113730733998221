import React from 'react';
import {Howl} from 'howler';

import getUniqueId from 'utils/getUniqueId';
import nosound from 'assets/nosound.mp3';

import './styles.scss';
import strings from 'strings';

const DASHARRAY_LENGTH = 230;

class AudioPlayer extends React.Component {
	constructor(props) {
		super(props);

		this.uniqueId = `audio${getUniqueId()}`;

		this.state = {
			isPlaying: false,
			duration: 0, // ms
			currentTime: 0,
			isDone: false,
			isStarted: false,
		};

		this.audio = new Howl({src: props.src});
		this.tick = this.tick.bind(this);
		this.handleStartPlayer = this.handleStartPlayer.bind(this);
		this.handlePause = this.handlePause.bind(this);
		this.handlePlay = this.handlePlay.bind(this);
	}

	componentDidMount() {
		if (this.props.autoplay) {
			this.handleStartPlayer();
		}
	}

	componentWillReceiveProps(nextProps) {
		const {src} = this.props;
		if (src && src !== nextProps.src) {
			this.audio = new Howl({src: nextProps.src});
			if (this.props.playOnSourceChanged) this.handleStartPlayer();
			else this.handlePause();
		}
	}

	componentDidUpdate(prevProps) {
		const {src} = this.props;
		if (prevProps.src && src !== prevProps.src) {
			this.audio.src = [src];
		}
	}

	componentWillUnmount() {
		cancelAnimationFrame(this.animationFrame);
		this.audio.stop();
		this.setState({
			isDone: true,
		});
	}

	updateProgress = () => {
		const duration = this.audio.duration();
		if (duration) {
			this.setState({
				currentTime: this.audio.seek(),
				duration: this.audio.duration(),
			});
		}
	};

	tick() {
		const hasStoppedPlaying = this.audio.ended || this.audio.paused;
		if (hasStoppedPlaying) {
			this.setState({
				isDone: true,
			});
		} else {
			this.updateProgress();
			this.setState({
				currentTime: this.audio.seek(),
			});
		}

		if (!this.state.isDone) {
			this.animationFrame = requestAnimationFrame(this.tick);
		}
	}

	completeCallback() {
		if (this.props.onComplete) {
			this.props.onComplete();
		}
	}

	handlePlay() {
		this.setState(
			{
				isDone: false,
				isPlaying: true,
			},
			() => {
				this.tick();
			}
		);

		if (typeof this.props.onStart === 'function') {
			this.props.onStart();
		}
	}

	handlePause() {
		this.setState({
			isPlaying: false,
		});

		const isEnded = !this.audio.playing();

		if (isEnded) {
			this.completeCallback();
		}

		cancelAnimationFrame(this.animationFrame);

		if (typeof this.props.onPause === 'function') {
			this.props.onPause();
		}
	}

	handleStartPlayer() {
		if (!this.audio.playing()) {
			this.audio.play();
			this.audio.volume(1);
			this.handlePlay();
		} else {
			this.audio.stop();
			this.audio.seek(0);
			this.handlePause();
		}
	}

	render() {
		const {disabled, inline} = this.props;

		const {currentTime, duration, isPlaying} = this.state;

		let progress = 0;
		if (isPlaying) {
			const percentageDone = (currentTime * 100) / duration;
			progress =
				DASHARRAY_LENGTH - (percentageDone * DASHARRAY_LENGTH) / 100;
		}

		let buttonClasses = 'lit-circle lit-audio';
		if (isPlaying) buttonClasses += ' is-playing';
		if (inline) buttonClasses += ' inline';

		return (
			<button
				className={buttonClasses}
				disabled={disabled}
				onClick={() => {
					if (!this.audio) {
						return;
					}
					this.handleStartPlayer();
					const audio = new Audio(nosound);
					audio.loop = true;
					audio.play();
				}}
				title={disabled ? strings.max2Listens : null}
			>
				<svg
					className="lit-circle__interactive"
					height="90"
					style={{enableBackground: 'new 0 0 90 90'}}
					version="1.1"
					viewBox="0 0 90 90"
					width="90"
					x="0px"
					xmlSpace="preserve"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					y="0px"
				>
					<g transform="rotate(-84 45 45)">
						<circle
							className="lit-audio__outline"
							cx="45"
							cy="45"
							fill="none"
							r="37"
							stroke="rgba(0,0,0,0.1)"
							strokeLinecap="round"
							strokeWidth="5"
						/>
						<circle
							className="lit-audio__progress"
							cx="45"
							cy="45"
							fill="none"
							r="37"
							stroke="#fff"
							strokeLinecap="round"
							strokeWidth="6"
							style={{
								strokeDashoffset: `${progress}`,
							}}
						/>
					</g>
				</svg>
				<div className="lit-audio__icon" />
			</button>
		);
	}
}

export default AudioPlayer;
