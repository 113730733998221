export const WMWAnswers = [
	['seng', 'fot'],
	['is', 'ku'],
	['glas', 'saks', 'hus'],
	['bil', 'ku', 'sol'],
	['is', 'blome', 'fot', 'ball'],
	['bamse', 'auge', 'hest', 'fly'],
	['genser', 'øyre', 'stol', 'hand', 'løve'],
	['glas', 'ku', 'måne', 'bil', 'vatn'],
	['bukse', 'tv', 'baby', 'eple', 'gåve', 'ski'],
	['avis', 'bilde', 'kopp', 'katt', 'snø', 'vegg'],
	['fly', 'penn', 'banan', 'pil', 'blå', 'stjerne', 'boks'],
	['skole', 'jakke', 'kaffi', 'briller', 'sykkel', 'gut', 'vindauge'],
];

export const WMNAnswers = [
	[7, 3],
	[9, 2],
	[8, 5, 1],
	[9, 4, 2],
	[1, 8, 4, 3],
	[3, 7, 2, 6],
	[5, 3, 7, 2, 1],
	[4, 1, 3, 6, 9],
	[1, 9, 5, 2, 7, 8],
	[3, 9, 7, 2, 4, 1],
	[1, 9, 4, 8, 3, 5, 2],
	[2, 7, 4, 1, 7, 9, 6],
];

export const WMNTrialAnswers = [
	[8, 5],
	[2, 9],
];
export const WMWTrialAnswers = [['Sol', 'Fly'], ['Fisk', 'Ski']];


export const SpoonerismAnswers = [
	{task: 'Bamse – Sofa', correct: ['Samse', 'Bofa']},
	{task: 'Lekse – Genser', correct: ['Gekse', 'Lenser']},
	{task: 'Bilde – Nase', correct: ['Nilde', 'Base']},
	{task: 'Sommar – Perle', correct: ['Pommar', 'Serle']},
	{task: 'Dele – Gardin', correct: ['Gele', 'Dardin']},
	{task: 'Sykkel – Rose', correct: ['Rykkel', 'Sose']},
	{task: 'Maske – Dame', correct: ['Daske', 'Mame']},
	{task: 'Pinne – Teppe', correct: ['Tinne', 'Peppe']},
	{task: 'Fotball – Kake', correct: ['Kotball', 'Fake']},
	{task: 'Vindauge – Nisse', correct: ['Nindauge', 'Visse']},
	{task: 'Dommar – Brikke', correct: ['Bommar', 'Drikke']},
	{task: 'Sjelden – Boks', correct: ['Belden', 'Sjoks']},
	{task: 'Kaffe – Brødre', correct: ['Baffe', 'Krødre']},
	{task: 'Varme – Hjort', correct: ['Jarme', 'Vort']},
	{task: 'Hjerte – Bord', correct: ['Berte', 'Jord']},
	{task: 'Motor – Ballong', correct: ['Botor', 'Mallong']},
	{task: 'Serviett – Jukse', correct: ['Jerviett', 'Sukse']},
	{task: 'Lande - Purre', correct: ['Pande', 'Lurre']},
	{task: 'Kjeks – Mais', correct: ['Meks', 'Kjais']},
	{task: 'Hår – Skjorte', correct: ['Skjår', 'Horte']},
];

export const SpoonerismIntroTasks = [
	{task: 'Bok - Dag', correct: ['Dok', 'Bag']},
	{task: 'Kjole - Fange', correct: ['Fole', 'Kjange']},
	{task: 'Musikk - Sjakk', correct: ['Sjusikk', 'Makk']},
];

export const phonemeIsolationIntro = [
	{
		id: 0,
		sound: 'g',
		options: {
			a: 'ball',
			b: 'sweater',
			c: 'fish',
			d: 'ski',
		},
		correct: 'b',
	},
	{
		id: 1,
		sound: 'h',
		options: {
			a: 'telephone',
			b: 'flower',
			c: 'motorcycle',
			d: 'house',
		},
		correct: 'd',
	},
];

export const phonemeIsolationTasks = [
	{
		id: 0,
		sound: 'b',
		options: {
			a: 'apple',
			b: 'bicycle',
			c: 'book',
			d: 'tractor',
		},
		correct: 'c',
		type: 'starts_with_',
	},
	{
		id: 1,
		sound: 'k',
		options: {
			a: 'shoe',
			b: 'cat',
			c: 'flower',
			d: 'plane',
		},
		correct: 'b',
		type: 'starts_with_',
	},
	{
		id: 2,
		sound: 'i',
		options: {
			a: 'icecream',
			b: 'chair',
			c: 'baby',
			d: 'horse',
		},
		correct: 'a',
		type: 'starts_with_',
	},
	{
		id: 3,
		sound: 'oe',
		options: {
			a: 'fireengine',
			b: 'ear',
			c: 'cup',
			d: 'teddy',
		},
		correct: 'b',
		type: 'starts_with_',
	},
	{
		id: 4,
		sound: 's',
		options: {
			a: 'jeans',
			b: 'purse',
			c: 'key',
			d: 'bicycle',
		},
		correct: 'd',
		type: 'starts_with_',
	},
	{
		id: 5,
		options: {
			a: 'crocodile',
			b: 'shoe',
			c: 'ski',
			d: 'key',
		},
		correct: 'b',
		sound: 'o',
		type: 'ends_with_',
	},
	{
		id: 6,
		options: {
			a: 'cup',
			b: 'chair',
			c: 'horse',
			d: 'book',
		},
		correct: 'a',
		sound: 'p',
		type: 'ends_with_',
	},
];
export const phonemeIsolationNormingTasks = [
	{
		id: 0,
		sound: 'b',
		options: {
			a: 'apple',
			b: 'bicycle',
			c: 'book',
			d: 'tractor',
		},
		correct: 'book',
		type: 'starts_with_',
	},
	{
		id: 1,
		sound: 'l',
		options: {
			a: 'foot',
			b: 'doll',
			c: 'nose',
			d: 'lion',
		},
		correct: 'lion',
		type: 'starts_with_',
	},
	{
		id: 2,
		sound: 'k',
		options: {
			a: 'shoe',
			b: 'cat',
			c: 'flower',
			d: 'plane',
		},
		correct: 'cat',
		type: 'starts_with_',
	},
	{
		id: 3,
		sound: 'e',
		options: {
			a: 'elephant',
			b: 'spoon',
			c: 'bed',
			d: 'fish',
		},
		correct: 'elephant',
		type: 'starts_with_',
	},
	{
		id: 4,
		sound: 'i',
		options: {
			a: 'horse',
			b: 'chair',
			c: 'baby',
			d: 'icecream',
		},
		correct: 'icecream',
		type: 'starts_with_',
	},
	{
		id: 5,
		sound: 'v',
		options: {
			a: 'rabbit',
			b: 'gloves',
			c: 'tractor',
			d: 'boot',
		},
		correct: 'gloves',
		type: 'starts_with_',
	},
	{
		id: 6,
		sound: 'oe',
		options: {
			a: 'fireengine',
			b: 'ear',
			c: 'cup',
			d: 'teddy',
		},
		correct: 'ear',
		type: 'starts_with_',
	},
	{
		id: 7,
		sound: 's',
		options: {
			a: 'jeans',
			b: 'purse',
			c: 'key',
			d: 'bicycle',
		},
		correct: 'bicycle',
		type: 'starts_with_',
	},
	{
		id: 8,
		sound: 't',
		options: {
			a: 'foot',
			b: 'eye',
			c: 'sun',
			d: 'bed',
		},
		correct: 'foot',
		type: 'ends_with_',
	},
	{
		id: 9,
		options: {
			a: 'crocodile',
			b: 'shoe',
			c: 'ski',
			d: 'key',
		},
		correct: 'shoe',
		sound: 'o',
		type: 'ends_with_',
	},
	{
		id: 10,
		options: {
			a: 'jeans',
			b: 'banana',
			c: 'sweater',
			d: 'saft',
		},
		correct: 'banana',
		sound: 'n',
		type: 'ends_with_',
	},
	{
		id: 11,
		options: {
			a: 'tractor',
			b: 'fish',
			c: 'icecream',
			d: 'cat',
		},
		correct: 'tractor',
		sound: 'r',
		type: 'ends_with_',
	},
	{
		id: 12,
		options: {
			a: 'reflex',
			b: 'plane',
			c: 'book',
			d: 'plowtruck',
		},
		correct: 'plane',
		sound: 'y',
		type: 'ends_with_',
	},
	{
		id: 13,
		options: {
			a: 'chair',
			b: 'cup',
			c: 'horse',
			d: 'book',
		},
		correct: 'cup',
		sound: 'p',
		type: 'ends_with_',
	},
	{
		id: 14,
		options: {
			a: 'bicycle',
			b: 'package',
			c: 'finger',
			d: 'fireengine',
		},
		correct: 'package',
		sound: 'e',
		type: 'ends_with_',
	},
	{
		id: 15,
		options: {a: 'horse', b: 'cup', c: 'porrige', d: 'cow'},
		correct: 'cow',
		sound: 'u',
		type: 'ends_with_',
	},
];

export const oneMinuteTasks = [
	'har',
	'og',
	'til',
	'den',
	'med',
	'ikkje',
	'vil',
	'seg',
	'høyr',
	'bok',
	'ho',
	'vere',
	'blir',
	'skal',
	'over',
	'hus',
	'eller',
	'andre',
	'berre',
	'kroner',
	'mange',
	'mellom',
	'ete',
	'penn',
	'første',
	'gjer',
	'skole',
	'norsk',
	'kvifor',
	'store',
	'kveite',
	'godt',
	'flagg',
	'fortel',
	'tidlegare',
	'rundt',
	'gjennom',
	'sjakk',
	'derfor',
	'korleis',
	'allereie',
	'framleis',
	'samtidig',
	'viktig',
	'kvart',
	'mogeleg',
	'bjørn',
	'kjole',
	'kanskje',
	'desember',
	'vanskeleg',
	'linjal',
	'skjorte',
	'kikkert',
	'kvarandre',
	'kjøpe',
	'spørsmål',
	'akkurat',
	'eigentleg',
	'keramikk',
	'tiggar',
	'selskapet',
	'skjedde',
	'kylling',
	'autograf',
	'kiste',
	'hjort',
	'hjarte',
	'pennal',
	'laurdag',
	'gjær',
	'kakao',
	'spiseskei',
	'kjøtt',
	'utvikling',
	'elefant',
	'ryggsekk',
	'kyrkje',
	'hjerne',
	'gardinene',
	'leverpostei ',
	'helikopter',
	'rabarbra',
	'tyggegummi',
	'forskjellige',
	'trampoline',
	'protein',
	'fortau',
	'musikk',
	'heilomvending',
	'slikkepott',
	'situasjonen',
	'ambivalent',
	'kravstor',
	'segne',
	'binders',
	'flugelort',
	'peikefinger',
	'landsforrædar',
	'bilmekanikar',
	'skjere',
	'perforere',
	'samansetning',
	'hallusinasjon',
	'spekulasjon',
	'kjeldesortering',
	'akvakultur',
	'avdramatisere',
	'dyskalkuli',
	'makulering',
	'ukulele',
	'klinkekule',
	'beredskapsplan',
	'byggeindustri',
	'kjekjøtt',
	'skjermdump',
	'lakseoppdrett',
	'utdanningsprogram',
	'beskjedent',
	'riksmeklingsmannen',
];

export const lkAnswersNorming = [
	'O',
	'O',
	'T',
	'T',
	'W',
	'W',
	'H',
	'H',
	'C',
	'C',
	'E',
	'E',
	'Y',
	'Y',
	'M',
	'M',
	'B',
	'B',
	'Z',
	'Z',
	'Ø',
	'Ø',
	'V',
	'V',
	'K',
	'K',
	'G',
	'G',
	'R',
	'R',
	'S',
	'S',
	'Æ',
	'Æ',
	'F',
	'F',
	'I',
	'I',
	'P',
	'P',
	'J',
	'J',
	'L',
	'L',
	'D',
	'D',
	'N',
	'N',
	'Å',
	'Å',
	'Q',
	'Q',
	'U',
	'U',
	'X',
	'X',
	'A',
	'A',
];
export const lkAnswers = [
	'O',
	'O',
	'E',
	'E',
	'I',
	'I',
	'S',
	'S',
	'T',
	'T',
	'U',
	'U',
	'N',
	'N',
	'Å',
	'Å',
	'G',
	'G',
	'D',
	'D',
	'Æ',
	'Æ',
	'Q',
	'Q',
];
export const kdlkAnswers = [
	'H',
	'H',
	'E',
	'E',
	'M',
	'M',
	'B',
	'B',
	'G',
	'G',
	'R',
	'R',
	'S',
	'S',
	'Æ',
	'Æ',
	'F',
	'F',
	'I',
	'I',
	'J',
	'J',
	'L',
	'L',
	'D',
	'D',
	'N',
	'N',
	'U',
	'U',
];

export const pdIntroSteps = [
	{
		task: 'Kan du seie "gris"?',
		expectedAnswer: 'gris',
	},
	{
		task: 'Kan du seie "gris" utan /g/?',
		expectedAnswer: 'ris',
	},
	{
		task: 'Kan du seie "føre"?',
		expectedAnswer: 'føre',
	},
	{
		task: 'Kan du seie "føre" utan /f/?',
		expectedAnswer: 'øre',
	},
	{
		task: 'Kan du seie "sel"?',
		expectedAnswer: 'sel',
	},
	{
		task: 'Kan du seie "sel" utan /l/?',
		expectedAnswer: 'se',
	},
];
export const pdSteps = [
	'gås',
	'mål',
	'brød',
	'klede',
	'brev',
	'trygg',
	'drap',
	'skyl',
	'lås',
	'bru',
	'klatre',
	'malt',
	'bry',
	'lekse',
	'sport',
	'loff',
];
export const pdAnswers = [
	'ås',
	'ål',
	'rød',
	'lede',
	'rev',
	'rygg',
	'rap',
	'sky',
	'lå',
	'bu',
	'klare',
	'mat',
	'by',
	'lese',
	'sort',
	'lo',
];

export const wdAnswersNorming = [
	'mase',
	'fly',
	'dra',
	'kråka',
	'melk',
	'tær',
	'rikke',
	'gråt',
	'bake',
	'regne',
	'hyle',
	'takk',
	'korleis',
	'verda',
	'ekkelt',
	'magisk',
	'ski',
	'leksefri',
	'historier',
	'flokk',
	'senger',
	'kanskje',
	'sjokolade',
	'hjul',
	'rundt',
	'sild',
	'skjelven',
	'godt',
	'kyllinger',
	'kjøtt',
	'smarttelefon',
	'fugl',
	'sykkelhjelm',
	'hjerte',
	'kjeveortoped',
	'blåbærsyltetøy',
	'biblioteket',
	'kjetting',
	'sannsyn',
	'abrakadabra',
];

export const wdAnswers = [
	'korleis',
	'verda',
	'magisk',
	'ski',
	'leksefri',
	'flokk',
	'kanskje',
	'rundt',
	'kyllingar',
	'blåbærsyltetøy',
	'biblioteket',
	'sannsyn',
	'abrakadabra',
];

export const rnIntroSteps = [
	'Intro',
	'Colors',
	'Images',
	'Colors and Images',
	'Ready to start',
];
export const rnAnswers = [
	[
		{color: 'red', item: 'bike'},
		{color: 'blue', item: 'teddy'},
		{color: 'grey', item: 'apple'},
		{color: 'red', item: 'bike'},
		{color: 'blue', item: 'sweater'},
		{color: 'orange', item: 'thumb'},
		{color: 'green', item: 'bike'},
	],
	[
		{color: 'grey', item: 'thumb'},
		{color: 'red', item: 'apple'},
		{color: 'orange', item: 'bike'},
		{color: 'orange', item: 'thumb'},
		{color: 'blue', item: 'sweater'},
		{color: 'green', item: 'teddy'},
		{color: 'blue', item: 'thumb'},
	],
	[
		{color: 'grey', item: 'apple'},
		{color: 'red', item: 'teddy'},
		{color: 'red', item: 'sweater'},
		{color: 'green', item: 'bike'},
		{color: 'grey', item: 'bike'},
		{color: 'blue', item: 'sweater'},
		{color: 'green', item: 'apple'},
	],
	[
		{color: 'orange', item: 'thumb'},
		{color: 'red', item: 'sweater'},
		{color: 'blue', item: 'apple'},
		{color: 'green', item: 'teddy'},
		{color: 'blue', item: 'thumb'},
		{color: 'green', item: 'teddy'},
		{color: 'orange', item: 'apple'},
	],
	[
		{color: 'grey', item: 'thumb'},
		{color: 'red', item: 'bike'},
		{color: 'orange', item: 'bike'},
		{color: 'grey', item: 'sweater'},
		{color: 'orange', item: 'bike'},
		{color: 'grey', item: 'sweater'},
		{color: 'orange', item: 'thumb'},
	],
	[
		{color: 'red', item: 'teddy'},
		{color: 'green', item: 'apple'},
		{color: 'grey', item: 'teddy'},
		{color: 'green', item: 'sweater'},
		{color: 'orange', item: 'apple'},
		{color: 'red', item: 'sweater'},
		{color: 'green', item: 'apple'},
	],
	[
		{color: 'grey', item: 'thumb'},
		{color: 'blue', item: 'teddy'},
		{color: 'grey', item: 'bike'},
		{color: 'orange', item: 'thumb'},
		{color: 'blue', item: 'sweater'},
		{color: 'green', item: 'teddy'},
		{color: 'red', item: 'apple'},
		{color: 'blue', item: 'teddy'},
	],
];
export const kdrnAnswers = [
	[
		{color: 'red', item: 'bike'},
		{color: 'blue', item: 'teddy'},
		{color: 'grey', item: 'apple'},
		{color: 'red', item: 'bike'},
		{color: 'blue', item: 'sweater'},
		{color: 'orange', item: 'thumb'},
	],
	[
		{color: 'green', item: 'bike'},
		{color: 'grey', item: 'thumb'},
		{color: 'red', item: 'apple'},
		{color: 'orange', item: 'bike'},
		{color: 'orange', item: 'thumb'},
		{color: 'blue', item: 'sweater'},
	],
	[
		{color: 'green', item: 'teddy'},
		{color: 'blue', item: 'thumb'},
		{color: 'grey', item: 'apple'},
		{color: 'red', item: 'teddy'},
		{color: 'red', item: 'sweater'},
		{color: 'green', item: 'bike'},
	],
	[
		{color: 'grey', item: 'bike'},
		{color: 'blue', item: 'sweater'},
		{color: 'green', item: 'apple'},
		{color: 'orange', item: 'thumb'},
		{color: 'red', item: 'sweater'},
		{color: 'blue', item: 'apple'},
		{color: 'green', item: 'teddy'},
	],
];
