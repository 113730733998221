import {Howl} from 'howler';

import no_spoonerismIntro from 'assets/sounds/no/spoonerism/intro.mp3';
import no_spoonerismIntroEnd from 'assets/sounds/no/spoonerism/intro-end.mp3';
import no_proveoppgave1 from 'assets/sounds/no/spoonerism/proveoppgave-1.mp3';
import no_proveoppgave2 from 'assets/sounds/no/spoonerism/proveoppgave-2.mp3';
import no_proveoppgave3 from 'assets/sounds/no/spoonerism/proveoppgave-3.mp3';
import no_correct1 from 'assets/sounds/no/spoonerism/correct-1.mp3';
import no_correct2 from 'assets/sounds/no/spoonerism/correct-2.mp3';
import no_correct3 from 'assets/sounds/no/spoonerism/correct-3.mp3';
import no_incorrect1 from 'assets/sounds/no/spoonerism/incorrect-1.mp3';
import no_incorrect2 from 'assets/sounds/no/spoonerism/incorrect-2.mp3';
import no_incorrect3 from 'assets/sounds/no/spoonerism/incorrect-3.mp3';
import no_sound1 from 'assets/sounds/no/spoonerism/spoonerism-task (1).mp3';
import no_sound2 from 'assets/sounds/no/spoonerism/spoonerism-task (2).mp3';
import no_sound3 from 'assets/sounds/no/spoonerism/spoonerism-task (3).mp3';
import no_sound4 from 'assets/sounds/no/spoonerism/spoonerism-task (4).mp3';
import no_sound5 from 'assets/sounds/no/spoonerism/spoonerism-task (5).mp3';
import no_sound6 from 'assets/sounds/no/spoonerism/spoonerism-task (6).mp3';
import no_sound7 from 'assets/sounds/no/spoonerism/spoonerism-task (7).mp3';
import no_sound8 from 'assets/sounds/no/spoonerism/spoonerism-task (8).mp3';
import no_sound9 from 'assets/sounds/no/spoonerism/spoonerism-task (9).mp3';
import no_sound10 from 'assets/sounds/no/spoonerism/spoonerism-task (10).mp3';
import no_sound11 from 'assets/sounds/no/spoonerism/spoonerism-task (11).mp3';
import no_sound12 from 'assets/sounds/no/spoonerism/spoonerism-task (12).mp3';
import no_sound13 from 'assets/sounds/no/spoonerism/spoonerism-task (13).mp3';
import no_sound14 from 'assets/sounds/no/spoonerism/spoonerism-task (14).mp3';
import no_sound15 from 'assets/sounds/no/spoonerism/spoonerism-task (15).mp3';
import no_sound16 from 'assets/sounds/no/spoonerism/spoonerism-task (16).mp3';
import no_sound17 from 'assets/sounds/no/spoonerism/spoonerism-task (17).mp3';
import no_sound18 from 'assets/sounds/no/spoonerism/spoonerism-task (18).mp3';
import no_sound19 from 'assets/sounds/no/spoonerism/spoonerism-task (19).mp3';
import no_sound20 from 'assets/sounds/no/spoonerism/spoonerism-task (20).mp3';

import nn_spoonerismIntro from 'assets/sounds/nn/spoonerism/intro.mp3';
import nn_spoonerismIntroEnd from 'assets/sounds/nn/spoonerism/intro-end.mp3';
import nn_proveoppgave1 from 'assets/sounds/nn/spoonerism/proveoppgave-1.mp3';
import nn_proveoppgave2 from 'assets/sounds/nn/spoonerism/proveoppgave-2.mp3';
import nn_proveoppgave3 from 'assets/sounds/nn/spoonerism/proveoppgave-3.mp3';
import nn_correct1 from 'assets/sounds/nn/spoonerism/correct-1.mp3';
import nn_correct2 from 'assets/sounds/nn/spoonerism/correct-2.mp3';
import nn_correct3 from 'assets/sounds/nn/spoonerism/correct-3.mp3';
import nn_incorrect1 from 'assets/sounds/nn/spoonerism/incorrect-1.mp3';
import nn_incorrect2 from 'assets/sounds/nn/spoonerism/incorrect-2.mp3';
import nn_incorrect3 from 'assets/sounds/nn/spoonerism/incorrect-3.mp3';
import nn_sound1 from 'assets/sounds/nn/spoonerism/spoonerism-task (1).mp3';
import nn_sound2 from 'assets/sounds/nn/spoonerism/spoonerism-task (2).mp3';
import nn_sound3 from 'assets/sounds/nn/spoonerism/spoonerism-task (3).mp3';
import nn_sound4 from 'assets/sounds/nn/spoonerism/spoonerism-task (4).mp3';
import nn_sound5 from 'assets/sounds/nn/spoonerism/spoonerism-task (5).mp3';
import nn_sound6 from 'assets/sounds/nn/spoonerism/spoonerism-task (6).mp3';
import nn_sound7 from 'assets/sounds/nn/spoonerism/spoonerism-task (7).mp3';
import nn_sound8 from 'assets/sounds/nn/spoonerism/spoonerism-task (8).mp3';
import nn_sound9 from 'assets/sounds/nn/spoonerism/spoonerism-task (9).mp3';
import nn_sound10 from 'assets/sounds/nn/spoonerism/spoonerism-task (10).mp3';
import nn_sound11 from 'assets/sounds/nn/spoonerism/spoonerism-task (11).mp3';
//import nn_sound12 from 'assets/sounds/nn/spoonerism/spoonerism-task (12).mp3';
//import nn_sound13 from 'assets/sounds/nn/spoonerism/spoonerism-task (13).mp3';
//import nn_sound14 from 'assets/sounds/nn/spoonerism/spoonerism-task (14).mp3';
//import nn_sound15 from 'assets/sounds/nn/spoonerism/spoonerism-task (15).mp3';
//import nn_sound16 from 'assets/sounds/nn/spoonerism/spoonerism-task (16).mp3';
//import nn_sound17 from 'assets/sounds/nn/spoonerism/spoonerism-task (17).mp3';
//import nn_sound18 from 'assets/sounds/nn/spoonerism/spoonerism-task (18).mp3';
//import nn_sound19 from 'assets/sounds/nn/spoonerism/spoonerism-task (19).mp3';
//import nn_sound20 from 'assets/sounds/nn/spoonerism/spoonerism-task (20).mp3';

export const no = {
	spoonerismIntro: new Howl({src: no_spoonerismIntro}),
	spoonerismIntroEnd: new Howl({src: no_spoonerismIntroEnd}),
	proveoppgave1: new Howl({src: no_proveoppgave1}),
	proveoppgave2: new Howl({src: no_proveoppgave2}),
	proveoppgave3: new Howl({src: no_proveoppgave3}),
	correct1: new Howl({src: no_correct1}),
	correct2: new Howl({src: no_correct2}),
	correct3: new Howl({src: no_correct3}),
	incorrect1: new Howl({src: no_incorrect1}),
	incorrect2: new Howl({src: no_incorrect2}),
	incorrect3: new Howl({src: no_incorrect3}),
};

export const nn = {
	spoonerismIntro: new Howl({src: nn_spoonerismIntro}),
	spoonerismIntroEnd: new Howl({src: nn_spoonerismIntroEnd}),
	proveoppgave1: new Howl({src: nn_proveoppgave1}),
	proveoppgave2: new Howl({src: nn_proveoppgave2}),
	proveoppgave3: new Howl({src: nn_proveoppgave3}),
	correct1: new Howl({src: nn_correct1}),
	correct2: new Howl({src: nn_correct2}),
	correct3: new Howl({src: nn_correct3}),
	incorrect1: new Howl({src: nn_incorrect1}),
	incorrect2: new Howl({src: nn_incorrect2}),
	incorrect3: new Howl({src: nn_incorrect3}),
};

export const no_test = {
	sound1: new Howl({src: no_sound1}),
	sound2: new Howl({src: no_sound2}),
	sound3: new Howl({src: no_sound3}),
	sound4: new Howl({src: no_sound4}),
	sound5: new Howl({src: no_sound5}),
	sound6: new Howl({src: no_sound6}),
	sound7: new Howl({src: no_sound7}),
	sound8: new Howl({src: no_sound8}),
	sound9: new Howl({src: no_sound9}),
	sound10: new Howl({src: no_sound10}),
	sound11: new Howl({src: no_sound11}),
	sound12: new Howl({src: no_sound12}),
	sound13: new Howl({src: no_sound13}),
	sound14: new Howl({src: no_sound14}),
	sound15: new Howl({src: no_sound15}),
	sound16: new Howl({src: no_sound16}),
	sound17: new Howl({src: no_sound17}),
	sound18: new Howl({src: no_sound18}),
	sound19: new Howl({src: no_sound19}),
	sound20: new Howl({src: no_sound20}),
};

export const nn_test = {
	sound1: new Howl({src: nn_sound1}),
	sound2: new Howl({src: nn_sound2}),
	sound3: new Howl({src: nn_sound3}),
	sound4: new Howl({src: nn_sound4}),
	sound5: new Howl({src: nn_sound5}),
	sound6: new Howl({src: nn_sound6}),
	sound7: new Howl({src: nn_sound7}),
	sound8: new Howl({src: nn_sound8}),
	sound9: new Howl({src: nn_sound9}),
	sound10: new Howl({src: nn_sound10}),
	sound11: new Howl({src: nn_sound11}),
	sound12: new Howl({src: no_sound12}),
	sound13: new Howl({src: no_sound13}),
	sound14: new Howl({src: no_sound14}),
	sound15: new Howl({src: no_sound15}),
	sound16: new Howl({src: no_sound16}),
	sound17: new Howl({src: no_sound17}),
	sound18: new Howl({src: no_sound18}),
	sound19: new Howl({src: no_sound19}),
	sound20: new Howl({src: no_sound20}),
};
