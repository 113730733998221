import React, {Component, Fragment} from 'react';

import ReadyButton from 'components/ReadyButton';
import AudioPlayer from 'components/AudioPlayer';

import spoonerismNo_intro from 'assets/sounds/no/spoonerism/intro.mp3';
import spoonerismNo_intro_end from 'assets/sounds/no/spoonerism/intro-end.mp3';
import spoonerismNN_intro from 'assets/sounds/nn/spoonerism/intro.mp3';
import spoonerismNN_intro_end from 'assets/sounds/nn/spoonerism/intro-end.mp3';
import {updateStepData} from 'api';

import listen from 'assets/listen.png';
import arrow from 'assets/arrow.png';

import {no, nn} from './assets';

import strings from 'strings';

class Intro extends Component {
	constructor(props) {
		super(props);
		switch (strings.getLanguage()) {
			case 'nn':
				this.assets = nn;
				this.intro_audio = spoonerismNN_intro;
				this.intro_audio_end = spoonerismNN_intro_end;
				break;
			case 'no':
				this.assets = no;
				this.intro_audio = spoonerismNo_intro;
				this.intro_audio_end = spoonerismNo_intro_end;
				break;
			default:
				this.assets = no;
				this.intro_audio = spoonerismNo_intro;
				this.intro_audio_end = spoonerismNo_intro_end;
				break;
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.currentStep !== this.props.currentStep) {
			this.startNewTask(newProps.currentStep);
		}
		if (newProps.stepData.answered && !this.props.stepData.answered) {
			this.playFeedback(newProps.currentStep, newProps.stepData.correct);
		}
	}
	startNewTask = (taskNumber) => {
		let audioFile;
		if (taskNumber === 1) {
			audioFile = this.assets.proveoppgave1;
		} else if (taskNumber === 2) {
			audioFile = this.assets.proveoppgave2;
		} else if (taskNumber === 3) {
			audioFile = this.assets.proveoppgave3;
		}
		if (audioFile) {
			const myCallback = () => {
				//audioFile.removeEventListener('ended', myCallback);
				//start timer
			};
			audioFile.on('end', myCallback);
			audioFile.play();
		}
	};

	initializeAudio = () => {
		Object.values(this.assets).forEach((audioEl) => audioEl.load());

		this.timeout = setTimeout(
			() => this.startNewTask(this.props.currentStep),
			1000
		);
	};

	playFeedback = (task, correct) => {
		let audioFile;
		if (task === 1) {
			audioFile = correct ? this.assets.correct1 : this.assets.incorrect1;
		} else if (task === 2) {
			audioFile = correct ? this.assets.correct2 : this.assets.incorrect2;
		} else if (task === 3) {
			audioFile = correct ? this.assets.correct3 : this.assets.incorrect3;
		}
		if (audioFile) {
			const myCallback = () => {
				//audioFile.removeEventListener('ended', myCallback);
				setTimeout(() => {
					updateStepData({feedbackDone: true});
				}, 2000);
				//start timer
			};
			audioFile.on('end', myCallback);
			audioFile.play();
		}
	};

	render() {
		const {currentStep} = this.props;

		return (
			<Fragment>
				{currentStep === 0 && (
					<Fragment>
						<p>
							<AudioPlayer
								autoplay
								inline
								src={this.intro_audio}
							/>
						</p>
						<p>
							{strings.formatString(
								strings.sp_intro1,
								<b>{'Gul og Sol'}</b>,
								<i>{strings.sp_intro_reminder}</i>,
								<i>{'første lyden'}</i>
							)}
						</p>
						<div className="spoonerism-examples">
							<div className="example">
								<div className="example-box">
									<b>{`G`}</b>UL - <b>{`S`}</b>OL
								</div>
								<div className="arrow">
									<img src={arrow} alt="" />
								</div>
								<div className="example-box">
									<b>{`S`}</b>UL - <b>{`G`}</b>OL
								</div>
							</div>
							<div className="example">
								<div className="example-box">
									<b>{`M`}</b>ÅNE - <b>{`V`}</b>EGG
								</div>
								<div className="arrow">
									<img src={arrow} alt="" />
								</div>
								<div className="example-box">
									<b>{`V`}</b>ÅNE - <b>{`M`}</b>EGG
								</div>
							</div>
							<div className="example">
								<div className="example-box">
									<b>{`K`}</b>YSS - <b>{`H`}</b>ATT
								</div>
								<div className="arrow">
									<img src={arrow} alt="" />
								</div>
								<div className="example-box">
									<b>{`H`}</b>YSS - <b>{`KJ`}</b>ATT
								</div>
							</div>
						</div>
						<ReadyButton onReadyChange={this.props.setReady} />
					</Fragment>
				)}
				{currentStep > 0 && currentStep < 4 && (
					<div className="Spoonerism">
						<img className="listen-image" src={listen} alt="" />
						<p>
							{strings.sp_instruction}
							<br />
							{strings.practiceTasks}
						</p>
					</div>
				)}
				{currentStep === 4 && (
					<Fragment>
						<p>
							<AudioPlayer
								autoplay
								inline
								src={
									this.intro_audio_end
								} /* {this.assets.spoonerismIntroEnd} */
							/>{' '}
							{strings.sp_intro3}
						</p>
						<ReadyButton onReadyChange={this.props.setReady} />
					</Fragment>
				)}
			</Fragment>
		);
	}
}

export default Intro;
