import React, {Component} from 'react';

import './styles.scss';
import {getLetterKnowledge} from 'constants/tests.js';

class Test extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentStep: 0,
		};
	}

	componentWillReceiveProps(newProps) {
		if (newProps.currentStep !== this.props.currentStep) {
			this.startNewTask(newProps.currentStep);
		}
	}

	componentDidMount() {}

	startNewTask = (taskNumber) => {
		this.setState({
			currentStep: taskNumber,
		});
	};

	render() {
		const {currentStep} = this.state;
		const steps = getLetterKnowledge(
			this.props.testKey,
			this.props.ageGroup
		).getSteps();

		return <div className="letter">{steps && steps[currentStep]}</div>;
	}
}

export default Test;
