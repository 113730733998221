import {Howl} from 'howler';

import no_trial_tall1 from 'assets/sounds/no/workingmemory_numbers/wmTrial-numbers-1.mp3';
import no_trial_tall2 from 'assets/sounds/no/workingmemory_numbers/wmTrial-numbers-2.mp3';
import no_trial_word1 from 'assets/sounds/no/workingmemory_words/wmTrial-words-1.mp3';
import no_trial_word2 from 'assets/sounds/no/workingmemory_words/wmTrial-words-2.mp3';


import no_correct1 from 'assets/sounds/no/workingmemory_numbers/correct-1.m4a';
import no_correct2 from 'assets/sounds/no/workingmemory_numbers/correct-1.m4a';

import no_incorrect1 from 'assets/sounds/no/workingmemory_numbers/incorrect-1.m4a';
import no_incorrect2 from 'assets/sounds/no/workingmemory_numbers/incorrect-2.m4a';

import no_ord_incorrect1 from 'assets/sounds/no/workingmemory_words/incorrect-3.m4a';
import no_ord_incorrect2 from 'assets/sounds/no/workingmemory_words/incorrect-4.m4a';

import nn_trial_word1 from 'assets/sounds/nn/workingmemory_words/wmTrial-words-1.mp3';
import nn_trial_word2 from 'assets/sounds/nn/workingmemory_words/wmTrial-words-2.mp3';

import no_tall1 from 'assets/sounds/no/workingmemory_numbers/workingmemory-numbers-1.mp3';
import no_tall2 from 'assets/sounds/no/workingmemory_numbers/workingmemory-numbers-2.mp3';
import no_tall3 from 'assets/sounds/no/workingmemory_numbers/workingmemory-numbers-3.mp3';
import no_tall4 from 'assets/sounds/no/workingmemory_numbers/workingmemory-numbers-4.mp3';
import no_tall5 from 'assets/sounds/no/workingmemory_numbers/workingmemory-numbers-5.mp3';
import no_tall6 from 'assets/sounds/no/workingmemory_numbers/workingmemory-numbers-6.mp3';
import no_tall7 from 'assets/sounds/no/workingmemory_numbers/workingmemory-numbers-7.mp3';
import no_tall8 from 'assets/sounds/no/workingmemory_numbers/workingmemory-numbers-8.mp3';
import no_tall9 from 'assets/sounds/no/workingmemory_numbers/workingmemory-numbers-9.mp3';
import no_tall10 from 'assets/sounds/no/workingmemory_numbers/workingmemory-numbers-10.mp3';
import no_tall11 from 'assets/sounds/no/workingmemory_numbers/workingmemory-numbers-11.mp3';
import no_tall12 from 'assets/sounds/no/workingmemory_numbers/workingmemory-numbers-12.mp3';

import no_word1 from 'assets/sounds/no/workingmemory_words/workingmemory-words-1.mp3';
import no_word2 from 'assets/sounds/no/workingmemory_words/workingmemory-words-2.mp3';
import no_word3 from 'assets/sounds/no/workingmemory_words/workingmemory-words-3.mp3';
import no_word4 from 'assets/sounds/no/workingmemory_words/workingmemory-words-4.mp3';
import no_word5 from 'assets/sounds/no/workingmemory_words/workingmemory-words-5.mp3';
import no_word6 from 'assets/sounds/no/workingmemory_words/workingmemory-words-6.mp3';
import no_word7 from 'assets/sounds/no/workingmemory_words/workingmemory-words-7.mp3';
import no_word8 from 'assets/sounds/no/workingmemory_words/workingmemory-words-8.mp3';
import no_word9 from 'assets/sounds/no/workingmemory_words/workingmemory-words-9.mp3';
import no_word10 from 'assets/sounds/no/workingmemory_words/workingmemory-words-10.mp3';
import no_word11 from 'assets/sounds/no/workingmemory_words/workingmemory-words-11.mp3';
import no_word12 from 'assets/sounds/no/workingmemory_words/workingmemory-words-12.mp3';

import nn_trial_tall1 from 'assets/sounds/nn/workingmemory_numbers/wmTrial-numbers-1.mp3';
import nn_trial_tall2 from 'assets/sounds/nn/workingmemory_numbers/wmTrial-numbers-2.mp3';
import nn_tall_correct1 from 'assets/sounds/nn/workingmemory_numbers/correct-1.m4a';
import nn_tall_correct2 from 'assets/sounds/no/workingmemory_numbers/correct-1.m4a';
import nn_tall_incorrect1 from 'assets/sounds/nn/workingmemory_numbers/incorrect-1.m4a';
import nn_tall_incorrect2 from 'assets/sounds/nn/workingmemory_numbers/incorrect-2.m4a';
import nn_ord_incorrect1 from 'assets/sounds/nn/workingmemory_words/incorrect-1.m4a';
import nn_ord_incorrect2 from 'assets/sounds/nn/workingmemory_words/incorrect-2.m4a';


import nn_tall1 from 'assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-1.mp3';
import nn_tall2 from 'assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-2.mp3';
import nn_tall3 from 'assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-3.mp3';
import nn_tall4 from 'assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-4.mp3';
import nn_tall5 from 'assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-5.mp3';
import nn_tall6 from 'assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-6.mp3';
import nn_tall7 from 'assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-7.mp3';
import nn_tall8 from 'assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-8.mp3';
import nn_tall9 from 'assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-9.mp3';
import nn_tall10 from 'assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-10.mp3';
import nn_tall11 from 'assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-11.mp3';
import nn_tall12 from 'assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-12.mp3';

import nn_word1 from 'assets/sounds/nn/workingmemory_words/workingmemory-words-1.mp3';
import nn_word2 from 'assets/sounds/nn/workingmemory_words/workingmemory-words-2.mp3';
import nn_word3 from 'assets/sounds/nn/workingmemory_words/workingmemory-words-3.mp3';
import nn_word4 from 'assets/sounds/nn/workingmemory_words/workingmemory-words-4.mp3';
import nn_word5 from 'assets/sounds/nn/workingmemory_words/workingmemory-words-5.mp3';
import nn_word6 from 'assets/sounds/nn/workingmemory_words/workingmemory-words-6.mp3';
import nn_word7 from 'assets/sounds/nn/workingmemory_words/workingmemory-words-7.mp3';
import nn_word8 from 'assets/sounds/nn/workingmemory_words/workingmemory-words-8.mp3';
import nn_word9 from 'assets/sounds/nn/workingmemory_words/workingmemory-words-9.mp3';
import nn_word10 from 'assets/sounds/nn/workingmemory_words/workingmemory-words-10.mp3';
import nn_word11 from 'assets/sounds/nn/workingmemory_words/workingmemory-words-11.mp3';
import nn_word12 from 'assets/sounds/nn/workingmemory_words/workingmemory-words-12.mp3';

export const no_numbers_trial = [
	new Howl({src: no_trial_tall1}),
	new Howl({src: no_trial_tall2}),
	
];

export const no_numbers_answers= {
	correct1: new Howl({src: no_correct1}),
	correct2: new Howl({src: no_correct2}),

	incorrect1: new Howl({src: no_incorrect1}),
	incorrect2: new Howl({src: no_incorrect2}),

}


export const nn_numbers_trial = [
	new Howl({src: nn_trial_tall1}),
	new Howl({src: nn_trial_tall2}),
	
];
export const nn_numbers_answers= {
	correct1: new Howl({src: nn_tall_correct1}),
	correct2: new Howl({src: nn_tall_correct2}),
	incorrect1: new Howl({src: nn_tall_incorrect1}),
	incorrect2: new Howl({src: nn_tall_incorrect2}),

}

export const no_words_trial = [
	new Howl({src: no_trial_word1}),
	new Howl({src: no_trial_word2}),
];

export const no_words_answers= {
	correct1: new Howl({src: no_correct1}),
	correct2: new Howl({src: no_correct1}),
	incorrect1: new Howl({src: no_ord_incorrect1}),
	incorrect2: new Howl({src: no_ord_incorrect2}),
}

export const nn_words_trial = [
	new Howl({src: nn_trial_word1}),
	new Howl({src: nn_trial_word2}),
];

export const nn_words_answers= {
	correct1: new Howl({src: nn_tall_correct1}),
	correct2: new Howl({src: nn_tall_correct1}),
	incorrect1: new Howl({src: nn_ord_incorrect1}),
	incorrect2: new Howl({src: nn_ord_incorrect2}),
}

export const no_numbers = [
	new Howl({src: no_tall1}),
	new Howl({src: no_tall2}),
	new Howl({src: no_tall3}),
	new Howl({src: no_tall4}),
	new Howl({src: no_tall5}),
	new Howl({src: no_tall6}),
	new Howl({src: no_tall7}),
	new Howl({src: no_tall8}),
	new Howl({src: no_tall9}),
	new Howl({src: no_tall10}),
	new Howl({src: no_tall11}),
	new Howl({src: no_tall12}),
];
export const nn_numbers = [
	new Howl({src: nn_tall1}),
	new Howl({src: nn_tall2}),
	new Howl({src: nn_tall3}),
	new Howl({src: nn_tall4}),
	new Howl({src: nn_tall5}),
	new Howl({src: nn_tall6}),
	new Howl({src: nn_tall7}),
	new Howl({src: nn_tall8}),
	new Howl({src: nn_tall9}),
	new Howl({src: nn_tall10}),
	new Howl({src: nn_tall11}),
	new Howl({src: nn_tall12}),
];


export const no_words = [
	new Howl({src: no_word1}),
	new Howl({src: no_word2}),
	new Howl({src: no_word3}),
	new Howl({src: no_word4}),
	new Howl({src: no_word5}),
	new Howl({src: no_word6}),
	new Howl({src: no_word7}),
	new Howl({src: no_word8}),
	new Howl({src: no_word9}),
	new Howl({src: no_word10}),
	new Howl({src: no_word11}),
	new Howl({src: no_word12}),
];
export const nn_words = [
	new Howl({src: nn_word1}),
	new Howl({src: nn_word2}),
	new Howl({src: nn_word3}),
	new Howl({src: nn_word4}),
	new Howl({src: nn_word5}),
	new Howl({src: nn_word6}),
	new Howl({src: nn_word7}),
	new Howl({src: nn_word8}),
	new Howl({src: nn_word9}),
	new Howl({src: nn_word10}),
	new Howl({src: nn_word11}),
	new Howl({src: nn_word12}),
];
