import React, {Component} from 'react';

import strings from 'strings';
import {updateStepData} from 'api';
import {no_words_trial,no_words_answers,nn_words_trial,nn_words_answers} from './assets';
import listen from 'assets/listen.png';

import './styles.scss';

class Trial extends Component {
	constructor(props) {
		super(props);

		switch (strings.getLanguage()) {
			 case 'nn':
                this.assets = nn_words_trial;
                this.answers_stuff = nn_words_answers;
				break;
			 
			case 'no':
			default:
                this.assets = no_words_trial;
                this.answers_stuff = no_words_answers;
				break;
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.currentStep !== this.props.currentStep) {
			this.startNewTask(newProps.currentStep);
        }
        if (newProps.stepData.answered && !this.props.stepData.answered) {
			this.playFeedback(newProps.currentStep, newProps.stepData.correct);
		}
	}

	componentDidMount() {
		if (this.button) this.button.click();
		this.initializeAudio();
	}
	componentWillUnmount() {
		this.stopSounds();
		clearTimeout(this.timeout);
	}

	initializeAudio = () => {
		Object.values(this.assets).forEach((audioEl) => audioEl.load());

		this.timeout = setTimeout(
			() => this.startNewTask(this.props.currentStep),
			1000
		);
    };
    
    playFeedback = (task, correct) => {
		let audioFile;
		if (task === 0) {
			audioFile = correct ? this.answers_stuff.correct1 : this.answers_stuff.incorrect1;
		}  else if (task === 1) {
			audioFile = correct ? this.answers_stuff.correct1 : this.answers_stuff.incorrect2;
        }
        else if (task === 2) {
			audioFile = correct ? this.answers_stuff.correct1 : this.answers_stuff.incorrect2;
        } 


        
		if (audioFile) {
			const myCallback = () => {
				//audioFile.removeEventListener('ended', myCallback);
				setTimeout(() => {
					updateStepData({feedbackDone: true});
				}, 2000);
				//start timer
			};
			audioFile.on('end', myCallback);
			audioFile.play();
		}
	};

	startNewTask = (taskNumber) => {
		this.stopSounds();
		this.playSound(taskNumber);
	};

	playSound = (sound) => {
		this.assets[sound].play();
	};

	stopSounds = () => {
		for (const sound of Object.values(this.assets)) {
			sound.pause();
		}
	};

	render() {
		return (
			<div className="WorkingMemory">
				<img className="listen-image" src={listen} alt="" />
				<p>{strings.wsw_test1}</p>
			</div>
		);
	}
}

export default Trial;
