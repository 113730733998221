import React, {Component, Fragment} from 'react';

import {getPhonemeIsolation} from 'constants/tests';
import ReadyButton from 'components/ReadyButton';
import Timer from 'components/Timer';

import './styles.scss';

import {images, getSounds} from './assets';
import strings from 'strings';

class Test extends Component {
	constructor(props) {
		super(props);

		this.sounds = getSounds(strings.getLanguage());

		this.state = {
			answers: {},
		};
	}

	componentWillReceiveProps(newProps) {
		if (this.props.currentStep !== newProps.currentStep) {
			if (newProps.currentStep || newProps.currentStep === 0) {
				if (
					newProps.currentStep <
					getPhonemeIsolation(
						this.props.testKeyType,
						this.props.ageGroup
					).getSteps().length
				)
					this.playSound(
						getPhonemeIsolation(
							this.props.testKeyType,
							this.props.ageGroup
						).getSteps()[newProps.currentStep].type +
							getPhonemeIsolation(
								this.props.testKeyType,
								this.props.ageGroup
							).getSteps()[newProps.currentStep].sound
					);
			}
		}
	}

	componentDidMount() {
		if (
			this.props.currentStep <
			getPhonemeIsolation(
				this.props.testKeyType,
				this.props.ageGroup
			).getSteps().length
		) {
			this.playSound(
				getPhonemeIsolation(
					this.props.testKeyType,
					this.props.ageGroup
				).getSteps()[this.props.currentStep].type +
					getPhonemeIsolation(
						this.props.testKeyType,
						this.props.ageGroup
					).getSteps()[this.props.currentStep].sound
			);
		}
	}

	selectOption = (option) => {
		if (this.selectOptionTimeout) return;

		this.stopSounds();

		this.selectOptionTimeout = setTimeout(() => {
			clearTimeout(this.selectOptionTimeout);
			this.selectOptionTimeout = null;
		}, 1000);

		const step = getPhonemeIsolation(
			this.props.testKeyType,
			this.props.ageGroup
		).getSteps()[this.props.currentStep];
		this.timer.pause();
		const timeUsed = this.timer.getTimeUsed();
		this.setState(
			{
				answers: {
					...this.state.answers,
					[this.props.currentStep]: {
						option,
						timeUsed,
						isCorrect: step.options[option] === step.correct,
					},
				},
			},
			() => {
				this.timer.reset();
				if (
					this.props.currentStep >=
					getPhonemeIsolation(
						this.props.testKeyType,
						this.props.ageGroup
					).getSteps().length -
						1
				) {
					this.props.submitPhonemeIsolation(this.state.answers);
				} else {
					this.props.nextStep();
				}
			}
		);
	};

	onTimerEnd = () => {
		const step = getPhonemeIsolation(
			this.props.testKeyType,
			this.props.ageGroup
		).getSteps()[this.props.currentStep + 1];
		if (step) {
			this.selectOption(null);
			if (this.timer) this.timer.start();
		}
	};

	playSound = (sound, volume = 1) => {
		this.sounds[sound].volume(volume);
		this.sounds[sound].play();

		this.sounds[sound].on('end', function () {
			/* console.log('START TIMER NOW'); */
			if (this.timer) this.timer.start();
		});
	};

	stopSounds = () => {
		for (const sound of Object.values(this.sounds)) {
			sound.pause();
		}
	};

	render() {
		const step = getPhonemeIsolation(
			this.props.testKeyType,
			this.props.ageGroup
		).getSteps()[this.props.currentStep];
		return (
			<div className="PhonemeIsolation">
				{step && (
					<div className="phoneme-options">
						{Object.entries(step.options).map(
							([optionKey, optionValue], index2) => (
								<div
									className="phoneme-option"
									onClick={() => this.selectOption(optionKey)}
									style={{
										backgroundImage: `url(${images[optionValue]})`,
									}}
								/>
							)
						)}
					</div>
				)}
				{!step && (
					<Fragment>
						<p>{strings.pi_done}</p>
						<ReadyButton onReadyChange={this.props.setReady} />
					</Fragment>
				)}
				<Timer
					resetOnStop
					ref={(node) => (this.timer = node)}
					duration={10}
					onEnd={this.onTimerEnd}
					hidden
				/>
			</div>
		);
	}
}

export default Test;
