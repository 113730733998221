import React, {Component} from 'react';
import strings from 'strings';

class Pause extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<div>
				<h2>{strings.pauseBeforeNextTest}</h2>
			</div>
		);
	}
}

export default Pause;
