import {ENDPOINT_URL} from 'constants/index';
import {apiErrorHandling} from 'utils/apiUtils';

const headers = new Headers();
headers.append('Content-Type', 'application/json');

export default function getTestKeyInfo(key) {
	return fetch(
		`${ENDPOINT_URL}/followupkeyinfo`,
		{
			method: 'POST',
			credentials: 'include',
		headers,
			body: JSON.stringify({
				key,
				
			}),
		}).then((response) => {
		if (!response.ok) {
			throw response;
		}
		return response.json();
	});
}