import React, {Component} from 'react';

import {getOneMinute} from 'constants/tests';
import {chunkArray} from 'utils/arrayUtils';

import './styles.scss';
class Test extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentStep: 0,
		};
	}

	componentWillReceiveProps(newProps) {
		if (newProps.currentStep !== this.props.currentStep) {
			this.startNewTask(newProps.currentStep);
		}
	}

	componentDidMount() {}

	startNewTask = (taskNumber) => {
		this.setState({
			currentStep: taskNumber,
		});
	};

	render() {
		const {currentStep} = this.state;
		const ITEMS_PER_COLUMN = 10;

		const rows = chunkArray(getOneMinute().getSteps(), ITEMS_PER_COLUMN);

		const currentRow = Math.floor(currentStep / ITEMS_PER_COLUMN);
		const currentItem = Math.floor(currentStep % ITEMS_PER_COLUMN);

		const row = rows[currentRow] || null;
		const previousRow = rows[currentRow - 1] || null;
		const nextRow = rows[currentRow + 1] || null;

		return (
			<>
				<div className="TestWrapper">
					<div className="column previous">
						{previousRow &&
							previousRow.map((item, index) => (
								<p key={index} className={`item`}>
									{item}
								</p>
							))}
					</div>
					<div className="column">
						{row &&
							row.map((item, index) => (
								<p
									key={index}
									className={`item ${
										currentItem === index && 'active'
									}`}
								>
									{item}
								</p>
							))}
					</div>
					<div className="column next">
						{nextRow &&
							nextRow.map((item, index) => (
								<p key={index} className={`item`}>
									{item}
								</p>
							))}
					</div>
				</div>
			</>
		);
	}
}

export default Test;
