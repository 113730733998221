import io from 'socket.io-client';

import {SOCKET_ENDPOINT} from './constants';

const socket = io(SOCKET_ENDPOINT, {
	reconnection: true,
	reconnectionDelay: 500,
	reconnectionAttempts: 30,
});

const getIdentity = () => {
	return sessionStorage.getItem('literate-identity');
};

//IDENTITY FOR SOCKET COMMUNICATION
socket.on('identity request', () => {
	if (typeof Storage !== 'undefined') {
		const identity = sessionStorage.getItem('literate-identity');
		if (!identity) {
			socket.emit('identity response');
			return;
		}
		socket.emit('identity response', identity);
		return;
	}

	socket.emit('identity response', socket.id);
});

socket.on('identity provision', (identity) => {
	sessionStorage.setItem('literate-identity', identity);
});

//Other stuff

export const requestRoomKey = (testKey, adminId) => {
	socket.emit('request room key', {testKey, adminId}, getIdentity());
};

export const subscribeToRoomConfig = (cb) => {
	socket.on('room config', (roomConfig) => {
		cb(roomConfig);
	});
};
export const subscribeToIdentityProvision = (cb) => {
	socket.on('identity provision', (identity) => {
		cb(identity);
	});
};

export const updateStage = (test, stage, step = 0, vars = {}) => {
	socket.emit('update stage', {test, stage, step, vars}, getIdentity());
};

export const updateAgeGroup = ({ageGroup}) => {
	socket.emit('update age group', {ageGroup}, getIdentity());
};

export const updateReady = (isReady) => {
	socket.emit('update ready', {isReady}, getIdentity());
};
export const updateLanguage = (language) => {
	socket.emit('update language', {language}, getIdentity());
};

export const joinRoom = (roomKey, secret = null, userRole = 'testUser') => {
	socket.emit('join room', roomKey, secret, userRole, getIdentity());
	const promise = new Promise(function (resolve, reject) {
		socket.on('invalid room key', reject);
		socket.on('invalid room', reject);
		socket.on('join room failed', reject);
		socket.on('room config', resolve);
	});

	return promise;
};

export const submitTestResults = (testId, results, authToken) => {
	socket.emit('submit results', {testId, results, authToken}, getIdentity());
};
export const submitCandidateInfo = (testKey, info, authToken,continued) => {
	socket.emit(
		'submit candidate info',
		{testKey, info, authToken,continued},
		getIdentity()
	);
};

export const updateStepData = (stepData) => {
	socket.emit('update step data', stepData, getIdentity());
};

export const startTimer = (test, stage, step) => {
	socket.emit('start timer', {test, stage, step}, getIdentity());
};

export const endTimer = (test, stage, step) => {
	socket.emit('end timer', {test, stage, step}, getIdentity());
};

//PING STUFF

export const pingServer = (pingresponse) => {
	socket.emit('ping-server', pingresponse, getIdentity());
};

export const pingClient = (pingresponse) => {
	socket.emit('ping-client', pingresponse, getIdentity());
};
export const subscribeToServerPong = (cb) => {
	socket.on('pong-server', (pingresponse) => {
		cb(pingresponse);
	});
};
export const subscribeToClientPong = (cb) => {
	socket.on('pong-client', (pingresponse) => {
		cb(pingresponse);
	});
};
export const subscribeToMasterPong = (cb) => {
	socket.on('pong-master', (pingresponse) => {
		cb(pingresponse);
	});
};

export const subscribeToMasterPing = (cb) => {
	socket.on('ping-master', (pingresponse) => {
		cb(pingresponse);
	});
};
export const subscribeToClientPing = (cb) => {
	socket.on('ping-client', (pingresponse) => {
		cb(pingresponse);
	});
};

export const masterPong = (payload) => {
	socket.emit('pong-master', payload, getIdentity());
};
export const clientPong = (payload) => {
	socket.emit('pong-client', payload, getIdentity());
};
