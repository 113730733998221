import React, {Component, Fragment} from 'react';

import ReadyButton from 'components/ReadyButton';
import AudioPlayer from 'components/AudioPlayer';

import no_workingMemoryWordsIntro from 'assets/sounds/no/intros/workingmemory-words-intro.mp3';
import nn_workingMemoryWordsIntro from 'assets/sounds/nn/intros/workingmemory-words-intro.mp3';
import strings from 'strings';

class Intro extends Component {
	constructor(props) {
		super(props);
		switch (strings.getLanguage()) {
			case 'nn':
				this.audio = nn_workingMemoryWordsIntro;
				break;
			case 'no':
				this.audio = no_workingMemoryWordsIntro;
				break;
			default:
				this.audio = no_workingMemoryWordsIntro;
				break;
		}
	}

	render() {
		return (
			<Fragment>
				<p>
					<AudioPlayer autoplay inline src={this.audio} />
					{strings.wsw_intro1} <br />
				</p>
				<ReadyButton onReadyChange={this.props.setReady} />
			</Fragment>
		);
	}
}

export default Intro;
