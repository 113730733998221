import React from 'react';

import {getWorkingMemory} from 'constants/tests.js';
import {updateStepData} from 'api';
import AdminTestPanel from 'components/AdminTestPanel';
import strings from 'strings';
import AdminReadyButton from 'components/AdminReadyButton';

const WM_STAGES = {
	NUMBERS_INTRO: 0,
	NUMBERS_TRIAL: 1,
	WAITING_POST_TRIAL: 2,
	NUMBERS_TEST: 3,
	WAITING: 4,
	WORDS_INTRO: 5,
	WORDS_TRIAL: 6,
	WAITING_POST_TRIAL2: 7,
	WORDS_TEST: 8,
	WAITING2: 9,
};
class WorkingMemoryWordsAdmin extends AdminTestPanel {
	constructor(props) {
		super(props, {
			stopOnConsecutiveErrors: true,
			enableTimers: false,
		});
	}

	getTestName = (flag) => {
		if (flag === 'words') return strings.wsw_testname;
		else return strings.wsn_testname;
	};

	componentWillReceiveProps(newProps) {
		super.componentWillReceiveProps(newProps);

		if (
			newProps.stepData.feedbackDone &&
			!this.props.stepData.feedbackDone &&
			newProps.stepData.answered
		) {
			this.answerCorrect();
		}
	}

	answerCorrect = (numberOfCorrect = true) => {
		const {results, correctAnswers, currentStage, currentStep} = this.state;
		const testName =
			currentStage === WM_STAGES.NUMBERS_TEST ? 'numbers' : 'words';

		const newResults = Object.assign({}, results, {
			[testName]: {
				...results[testName],
				[currentStep]: {
					isCorrect: numberOfCorrect,
					isAnswered: true,
				},
			},
		});

		this.setState({
			results: newResults,
			correctAnswers: correctAnswers + 1,
			consecutiveErrors: 0,
		});
		this.props.nextStep(newResults); //changing state object to regular object
	};

	answerWrong = () => {
		const {consecutiveErrors, currentStage, currentStep, results, options} =
			this.state;
		//ToDO check this one outs
		const testName =
			currentStage === WM_STAGES.NUMBERS_TEST ? 'numbers' : 'words';

		const newResults = Object.assign({}, results, {
			[testName]: {
				...results[testName],
				[currentStep]: {
					isCorrect: false,
					isAnswered: true,
				},
			},
		});

		this.setState(
			{
				wrongAnswers: this.state.wrongAnswers + 1,
				results: newResults,
				consecutiveErrors: consecutiveErrors + 1,
			},
			() => {
				const currErrors = this.state.consecutiveErrors;
				const maxErrors = options.maxConsecutiveErrors;
				if (
					currErrors >= maxErrors &&
					options.stopOnConsecutiveErrors
				) {
					if (testName === 'words')
						this.props.submitResults(this.state.results);
					this.props.nextStage();
				} else {
					this.props.nextStep(this.state.results);
				}
			}
		);
	};

	answerUnanswered = () => {
		const {consecutiveErrors, currentStage, currentStep, results, options} =
			this.state;

		const testName =
			currentStage === WM_STAGES.NUMBERS_TEST ? 'numbers' : 'words';

		const newResults = Object.assign({}, results, {
			[testName]: {
				...results[testName],
				[currentStep]: {
					isCorrect: false,
					isAnswered: false,
				},
			},
		});

		this.setState(
			{
				unanswered: this.state.unanswered + 1,
				results: newResults,
				consecutiveErrors: consecutiveErrors + 1,
			},
			() => {
				const currErrors = this.state.consecutiveErrors;
				const maxErrors = options.maxConsecutiveErrors;
				if (
					currErrors >= maxErrors &&
					options.stopOnConsecutiveErrors
				) {
					if (testName === 'words')
						this.props.submitResults(this.state.results);
					this.props.nextStage();
				} else {
					this.props.nextStep(this.state.results);
				}
			}
		);
	};

	handleKeyPress = (event) => {
		const {
			options: {limitHotkeysToTest, enableHotkeys},
			currentStage,
		} = this.state;
		if (
			(!limitHotkeysToTest || currentStage === 3 || currentStage === 8) &&
			enableHotkeys
		) {
			if (event.key === 'a') {
				this.answerCorrect();
			}
			if (event.key === 's') {
				this.answerWrong();
			}
			if (event.key === 'd') {
				this.answerUnanswered();
			}
		} else if (
			(currentStage === 1 || currentStage === 6) &&
			enableHotkeys
		) {
			if (event.key === 'a') {
				this.answer(1);
			}
			if (event.key === 's') {
				this.answer(0);
			}
			if (event.key === 'd') {
				this.answer(0);
			}
		}
	};
	answer = (numberOfCorrect) => {
		if (numberOfCorrect === 1) {
			updateStepData({
				answered: true,
				correct: true,
				feedbackDone: false,
			});
		} else
			updateStepData({
				answered: true,
				correct: false,
				feedbackDone: false,
			});
	};

	renderIntro = (version) => {
		return (
			<>
				<p>
					{strings.ws_admin1}
					<br />
					{version === 'numbers'
						? strings.wsn_admin2
						: strings.wsw_admin2}
					<br />

					{version === 'numbers'
						? strings.wsn_admin2part2
						: strings.wsw_admin2part2}
				</p>

				<p>
					{strings.getLanguage() === 'no'
						? strings.clickStartWhenReadyEmpty
						: strings.clickStartWhenReady}
				</p>

				<p>{strings.workingMemoryClickStart}</p>
				<AdminReadyButton
					isPractiseTasks
					remoteIsReady={this.props.candidateReady}
					onClick={this.props.nextStep}
				/>
			</>
		);
	};

	renderButtonsForTrials = () => (
		<>
			<button
				disabled={this.props.stepData.answered}
				onClick={(e) => {
					e.stopPropagation();
					this.answer(1);
					/* 	this.props.nextStep(); */
				}}
			>
				{strings.hotkeyButtonCorrect}
			</button>
			<button
				disabled={this.props.stepData.answered}
				onClick={(e) => {
					e.stopPropagation();
					this.answer(0);
					/* 	this.props.nextStep(); */
				}}
			>
				{strings.hotkeyButtonWrong}
			</button>
			<button
				disabled={this.props.stepData.answered}
				onClick={(e) => {
					e.stopPropagation();
					this.answer(0);
					/* 	this.props.nextStep(); */
				}}
			>
				{strings.hotkeyButtonUnanswered}
			</button>
		</>
	);

	renderButtons = () => (
		<>
			<button
				onClick={(e) => {
					e.stopPropagation();
					this.answerCorrect();
				}}
			>
				{strings.hotkeyButtonCorrect}
			</button>
			<button
				onClick={(e) => {
					e.stopPropagation();
					this.answerWrong();
				}}
			>
				{strings.hotkeyButtonWrong}
			</button>
			<button
				onClick={(e) => {
					e.stopPropagation();
					this.answerUnanswered();
				}}
			>
				{strings.hotkeyButtonUnanswered}
			</button>
		</>
	);

	renderTrial = (type) => {
		const {currentStep} = this.state;

		const dataset =
			type === 'numbers'
				? getWorkingMemory().getNumberTrialSteps()
				: getWorkingMemory().getWordTrialSteps();

		const row = dataset[currentStep].slice().reverse().join(' - ');

		return (
			<div>
				<p>
					<br />
					{type === 'numbers'
						? strings.wsn_admin3
						: strings.wsw_admin3}
					<br />
					{strings.hotkeyDescription}
					<br />
					{strings.hotkeyCorrect}
					<br />
					{strings.hotkeyWrong}
				</p>
				<p>
					{strings.formatString(
						strings.taskNumOf,
						currentStep + 1,
						dataset.length
					)}
				</p>
				<p>
					{strings.formatString(strings.expectedAnswer, <b>{row}</b>)}
				</p>
				{this.renderButtonsForTrials()}
			</div>
		);
	};

	renderTest = (type) => {
		const {currentStep} = this.state;

		const dataset =
			type === 'numbers'
				? getWorkingMemory().getNumberSteps()
				: getWorkingMemory().getWordSteps();

		const row = dataset[currentStep].slice().reverse().join(' - ');

		return (
			<div>
				<p>
					<br />
					{type === 'numbers'
						? strings.wsn_admin3
						: strings.wsw_admin3}
					<br />
					{strings.hotkeyDescription}
					<br />
					{strings.hotkeyCorrect}
					<br />
					{strings.hotkeyWrong}
				</p>
				<p>
					{strings.formatString(
						strings.taskNumOf,
						currentStep + 1,
						dataset.length
					)}
				</p>
				<p>
					{strings.formatString(strings.expectedAnswer, <b>{row}</b>)}
				</p>
				{this.renderButtons()}
			</div>
		);
	};

	renderWaiting = () => {
		return (
			<div>
				<p>
					{strings.formatString(
						strings.testDone,
						strings.ws_testname
					)}
				</p>
				<button onClick={this.props.nextStage}>
					{strings.nextTest}
				</button>
			</div>
		);
	};

	renderWaitingTrial = (type) => {
		return (
			<div>
				<p>{strings.sp_intro3}</p>
				<button onClick={this.props.nextStage}>
					{strings.nextTest}
				</button>
			</div>
		);
	};

	render() {
		const {currentStage: stage} = this.state;

		return (
			<div className="lit-intro">
				<h1 className="lit-page-title">
					{[0, 1, 2, 3, 4].includes(stage)
						? this.getTestName('numbers')
						: this.getTestName('words')}
				</h1>

				{stage === WM_STAGES.NUMBERS_INTRO &&
					this.renderIntro('numbers')}
				{stage === WM_STAGES.NUMBERS_TRIAL &&
					this.renderTrial('numbers')}
				{stage === WM_STAGES.WAITING_POST_TRIAL &&
					this.renderWaitingTrial('numbers')}
				{stage === WM_STAGES.NUMBERS_TEST && this.renderTest('numbers')}
				{stage === WM_STAGES.WAITING && this.renderWaiting()}
				{stage === WM_STAGES.WORDS_INTRO && this.renderIntro('words')}
				{stage === WM_STAGES.WORDS_TRIAL && this.renderTrial('words')}
				{stage === WM_STAGES.WAITING_POST_TRIAL2 &&
					this.renderWaitingTrial('words')}

				{stage === WM_STAGES.WORDS_TEST && this.renderTest('words')}
				{stage === WM_STAGES.WAITING2 && this.renderWaiting()}
			</div>
		);
	}
}

export default WorkingMemoryWordsAdmin;
