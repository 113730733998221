import {IS_NORMING_VARIANT} from 'constants/index';

const TEST_IDS = {
	WorkingMemory: 0,
	RapidNaming: 3,
	OneMinute: 4,
	Spoonerism: 5,
	LetterKnowledge: 6,
	PhonemeIsolation: 7,
	PhonemeDeletion: 8,
	WordDictation: 9,
};

const normingBhg = {
	1: 'LetterKnowledge',
	2: 'PhonemeIsolation',
	3: 'WorkingMemory',
	4: 'RapidNaming',
	5: 'TestComplete',
};
const norming123 = {
	1: 'LetterKnowledge',
	2: 'PhonemeIsolation',
	3: 'WorkingMemory',
	4: 'RapidNaming',
	5: 'PhonemeDeletion',
	6: 'OneMinute',
	7: 'WordDictation',
	8: 'TestComplete',
};
const norming4567 = {
	1: 'PhonemeIsolation',
	2: 'WorkingMemory',
	3: 'RapidNaming',
	4: 'PhonemeDeletion',
	5: 'OneMinute',
	6: 'WordDictation',
	7: 'TestComplete',
};
const normingYouthAdult = {
	1: 'WorkingMemory',
	2: 'RapidNaming',
	3: 'OneMinute',
	4: 'Spoonerism',
	5: 'TestComplete',
};

// const normingAll = {
// 	1: 'LetterKnowledge', bhg - 3
// 	2: 'PhonemeIsolation', bhg - 7
// 	3: 'WorkingMemory', alt
// 	4: 'RapidNaming', alt
// 	5: 'PhonemeDeletion', 1-7
// 	6: 'OneMinute', 1-voksen
// 	8: 'WordDictation', 1-7
// 	7: 'Spoonerism', ung/voksen
// 	9: 'TestComplete',
// };

const youthAndAdult = {
	1: 'WorkingMemory',
	2: 'RapidNaming',
	3: 'OneMinute',
	4: 'Spoonerism',
	5: 'TestComplete',
};

const grade0And1 = {
	1: 'LetterKnowledge',
	2: 'PhonemeIsolation',
	3: 'WorkingMemory',
	4: 'RapidNaming',
	5: 'TestComplete',
};

const grade2 = {
	1: 'WorkingMemory',
	2: 'RapidNaming',
	3: 'PhonemeDeletion',
	4: 'OneMinute',
	5: 'TestComplete',
};

const grade34567 = {
	1: 'WorkingMemory',
	2: 'RapidNaming',
	3: 'PhonemeDeletion',
	4: 'OneMinute',
	5: 'WordDictation',
	6: 'TestComplete',
};

const dysmateChildGrade0and1 = {
	1: 'LetterKnowledge',
	2: 'PhonemeIsolation',
	3: 'WorkingMemory',
	4: 'RapidNaming',
	5: 'TestComplete',
};
const dysmateChildGrade234567 = {
	1: 'WorkingMemory',
	2: 'RapidNaming',
	3: 'PhonemeDeletion',
	4: 'OneMinute',
	5: 'TestComplete',
};

const testsPerGrade = {
	0: grade0And1,
	1: grade0And1,
	2: grade2,
	3: grade34567,
	4: grade34567,
	5: grade34567,
	6: grade34567,
	7: grade34567,
	8: youthAndAdult,
	9: youthAndAdult,
	10: youthAndAdult,
	studiespesialisering: youthAndAdult,
	yrkesfag: youthAndAdult,
	bachelor: youthAndAdult,
	master: youthAndAdult,
	phd: youthAndAdult,
	other: youthAndAdult,
};
const normingTestsPerGrade = {
	0: normingBhg,
	1: norming123,
	2: norming123,
	3: norming123,
	4: norming4567,
	5: norming4567,
	6: norming4567,
	7: norming4567,
	8: normingYouthAdult,
	9: normingYouthAdult,
	10: normingYouthAdult,
	studiespesialisering: normingYouthAdult,
	yrkesfag: normingYouthAdult,
	bachelor: normingYouthAdult,
	master: normingYouthAdult,
	phd: normingYouthAdult,
	other: normingYouthAdult,
};
const dysmateChild2TestsPerGrade = {
	0: dysmateChildGrade0and1,
	1: dysmateChildGrade0and1,
	2: dysmateChildGrade234567,
	3: dysmateChildGrade234567,
	4: dysmateChildGrade234567,
	5: dysmateChildGrade234567,
	6: dysmateChildGrade234567,
	7: dysmateChildGrade234567,
	8: normingYouthAdult,
	9: normingYouthAdult,
	10: normingYouthAdult,
	studiespesialisering: normingYouthAdult,
	yrkesfag: normingYouthAdult,
	bachelor: normingYouthAdult,
	master: normingYouthAdult,
	phd: normingYouthAdult,
	other: normingYouthAdult,
};

export const testNumbers = youthAndAdult;
export const getNameFromNumber = (testNumber, age, testKeyType) => {
	return getTestsForClass(age, testKeyType)[testNumber];
};

export const getTestsForClass = (age, testKeyType) => {
	if (testKeyType === 'youth-followup') {
		if (normingTestsPerGrade[age]) {
			return normingTestsPerGrade[age];
		} else throw new Error('Invalid grade given');
	} else if (testKeyType === 'adult-followup') {
		return normingTestsPerGrade[8];
	} else if (testKeyType === 'child-followup') {
		if (dysmateChild2TestsPerGrade[age]) {
			return dysmateChild2TestsPerGrade[age];
		} else throw new Error('Invalid grade given');
	} else {
		if (testsPerGrade[age]) {
			return testsPerGrade[age];
		} else throw new Error('Invalid grade given');
	}
};

export const getTestIDFromName = (testNumber, age, testKeyType) => {
	const name = getTestsForClass(age, testKeyType)[testNumber];
	return TEST_IDS[name];
};
