import React, {Component, Fragment} from 'react';

import './styles.scss';

class ProgressIndication extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const {tests, roomConfig} = this.props;
		return (
			<div className="progress">
				{tests.map(([keyString, value], num) => {
					
					const key = Number(keyString);
					let className = 'progress__test';
					let lineClassName = 'progress__line';
					if (roomConfig) {
						if (roomConfig.results) {
							// if (roomConfig.results.hasOwnProperty(key)) {
							// 	className += ' completed';
							// 	lineClassName += ' completed';
							// }
						}
						if (roomConfig.currentTest === key) {
							if(this.props.subTestsTaken && this.props.subTestsTaken.includes(value)){
								className += ' currentAndTaken';
							}
							else{className += ' current';}
						
							
						}
						if(this.props.subTestsTaken && this.props.subTestsTaken.includes(value)){
							className += ' taken';
						}
						
							
					}
					return (
						<Fragment key={keyString}>
							<div className={className}>{key}</div>
							{num < tests.length - 1 && (
								<div className={lineClassName} />
							)}
							
						</Fragment>
					);
				})}
			</div>
		);
	}
}

export default ProgressIndication;
