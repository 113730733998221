import React, {Component} from 'react';

import './styles.scss';
import {getRapidNaming} from 'constants/tests.js';

import grabamse from 'assets/images/rapidNaming/grabamse.png';
import gragenser from 'assets/images/rapidNaming/gragenser.png';
import graeple from 'assets/images/rapidNaming/graeple.png';
import gratommel from 'assets/images/rapidNaming/gratommel.png';
import grasykkel from 'assets/images/rapidNaming/grasykkel.png';

import blabamse from 'assets/images/rapidNaming/blabamse.png';
import blagenser from 'assets/images/rapidNaming/blagenser.png';
import blaeple from 'assets/images/rapidNaming/blaeple.png';
import blatommel from 'assets/images/rapidNaming/blatommel.png';
import blasykkel from 'assets/images/rapidNaming/blasykkel.png';

import oransjebamse from 'assets/images/rapidNaming/oransjebamse.png';
import oransjegenser from 'assets/images/rapidNaming/oransjegenser.png';
import oransjeeple from 'assets/images/rapidNaming/oransjeeple.png';
import oransjetommel from 'assets/images/rapidNaming/oransjetommel.png';
import oransjesykkel from 'assets/images/rapidNaming/oransjesykkel.png';

import rodbamse from 'assets/images/rapidNaming/rodbamse.png';
import rodgenser from 'assets/images/rapidNaming/rodgenser.png';
import rodeple from 'assets/images/rapidNaming/rodeple.png';
import rodtommel from 'assets/images/rapidNaming/rodtommel.png';
import rodsykkel from 'assets/images/rapidNaming/rodsykkel.png';

import gronnbamse from 'assets/images/rapidNaming/gronnbamse.png';
import gronngenser from 'assets/images/rapidNaming/gronngenser.png';
import gronneple from 'assets/images/rapidNaming/gronneple.png';
import gronntommel from 'assets/images/rapidNaming/gronntommel.png';
import gronnsykkel from 'assets/images/rapidNaming/gronnsykkel.png';

const images = {
	greyteddy: grabamse,
	greysweater: gragenser,
	greyapple: graeple,
	greythumb: gratommel,
	greybike: grasykkel,
	blueteddy: blabamse,
	bluesweater: blagenser,
	blueapple: blaeple,
	bluethumb: blatommel,
	bluebike: blasykkel,
	orangeteddy: oransjebamse,
	orangesweater: oransjegenser,
	orangeapple: oransjeeple,
	orangethumb: oransjetommel,
	orangebike: oransjesykkel,
	redteddy: rodbamse,
	redsweater: rodgenser,
	redapple: rodeple,
	redthumb: rodtommel,
	redbike: rodsykkel,
	greenteddy: gronnbamse,
	greensweater: gronngenser,
	greenapple: gronneple,
	greenthumb: gronntommel,
	greenbike: gronnsykkel,
};

const getImage = (color, item) => {
	if (images[color + item]) return images[color + item];
	console.error('Item ' + color + item + ' not found');
	return null;
};

class Test extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentStep: 0,
		};
	}

	componentWillReceiveProps(newProps) {
		if (newProps.currentStep !== this.props.currentStep) {
			this.startNewTask(newProps.currentStep);
		}
	}

	componentDidMount() {}

	startNewTask = (taskNumber) => {
		this.setState({
			currentStep: taskNumber,
		});
	};

	render() {
		const {currentStep} = this.state;
		const steps = getRapidNaming(this.props.ageGroup).getAnswers();
		const itemsPerRow = steps[0].length;
		const currentRow = Math.min(
			Math.floor(currentStep / itemsPerRow),
			steps.length - 1
		);
		const currentItem = Math.floor(currentStep % itemsPerRow);

		const row = steps[currentRow] || null;
		const previousRow = steps[currentRow - 1] || null;
		const nextRow = steps[currentRow + 1] || null;

		return (
			<div className="ScrollingTest">
				<div className="row previous">
					{previousRow &&
						previousRow.map((item, index) => (
							<p key={index} className={`item`}>
								<img
									src={getImage(item.color, item.item)}
									alt={`A ${item.color} ${item.item}`}
								/>
								{(currentRow - 1) * itemsPerRow + index + 1}
							</p>
						))}
				</div>
				<div className="row">
					{row &&
						row.map((item, index) => (
							<p
								key={index}
								className={`item${
									currentItem === index ? ' active' : ''
								}`}
							>
								<img
									src={getImage(item.color, item.item)}
									alt={`A ${item.color} ${item.item}`}
								/>
								{currentRow * itemsPerRow + index + 1}
							</p>
						))}
				</div>
				<div className="row next">
					{nextRow &&
						nextRow.map((item, index) => (
							<p key={index} className={`item`}>
								<img
									src={getImage(item.color, item.item)}
									alt={`A ${item.color} ${item.item}`}
								/>
								{(currentRow + 1) * itemsPerRow + index + 1}
							</p>
						))}
				</div>
			</div>
		);
	}
}

export default Test;
