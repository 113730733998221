import LocalizedStrings from 'react-localization';

import no from 'languages/no';
import nn from 'languages/nn';

let strings = new LocalizedStrings({
	no,
	nn,
});
strings.setLanguage('no');

export default strings;
