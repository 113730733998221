import React, {Component} from 'react';
import './App.scss';

import TestAdmin from 'pages/testadmin';
import TestUser from 'pages/testuser';
import SelectRole from 'pages/SelectRole';
import dysmateLog from 'assets/images/logo-white.png';
import EnvironmentBanner from 'components/EnvironmentBanner';
import {ENVIRONMENT} from 'constants/index';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			roomKey: '',
			testKey: '',
			testKeyType: '',
			continued:null,
			section: 'initial',
			failStart: false,
		};
	}

	onSelectRole = (role, key, testKeyType, adminEmail = null,continued) => {
		
		this.detectFailStart(role);
		if (role === 'initial') {
			this.setState({
				section: 'initial',
				testKey: '',
				roomKey: '',
				adminEmail: '',
			});
		} else if (role === 'testadmin') {
			this.setState({
				section: 'testadmin',
				testKey: key,
				adminEmail,
				testKeyType,
				continued
			});
		} else if (role === 'testuser') {
			this.setState({
				section: 'testuser',
				roomKey: key,
				testKeyType,
			});
		}
	};

	detectFailStart(role) {
		if (role === 'initial') {
			this.setState({
				failStart: true,
			});
		}
	}
	render() {
		const {section, roomKey, testKey, testKeyType, adminEmail,continued} = this.state;
		return (
			<div className="App">
				<EnvironmentBanner environment={ENVIRONMENT} />
				{section === 'initial' && (
					<SelectRole
						onSelectRole={this.onSelectRole}
						failStart={this.state.failStart}
					/>
				)}
				{section === 'testadmin' && (
					<TestAdmin
						testKey={testKey}
						testKeyType={testKeyType}
						adminId={adminEmail}
						onSelectRole={this.onSelectRole}
						testContinued={continued}
					/>
				)}
				{section === 'testuser' && (
					<TestUser
						roomKey={roomKey}
						testKeyType={testKeyType}
						onSelectRole={this.onSelectRole}
					/>
				)}
				{section === 'redirect' && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							textAlign: 'center',
							alignItems: 'center',
							justifyContent: 'space-between',
							minHeight: '100vh',
						}}
					>
						<img
							src={dysmateLog}
							alt="Literate"
							className="title__logo"
						/>
						<div>
							<h1>Siden er deaktivert</h1>
							<p>
								Literate-child er nå deaktivert til fordel for
								vår nye normeringsside som gjelder for alt fra
								barnehage til voksne. <br />
								Du kan fortsatt bruke den nye siden på{' '}
								<a href="https://literate-normering.herokuapp.com">
									Literate normering
								</a>
							</p>
							<p>
								<a href="https://literate-normering.herokuapp.com">
									<button
										style={{
											fontSize: 24,
											padding: '20px 40px',
										}}
									>
										Gå til ny normeringsside
									</button>
								</a>
							</p>
						</div>
						<div></div>
						<div></div>
					</div>
				)}
			</div>
		);
	}
}

export default App;
