import React, {Component, Fragment} from 'react';

import './styles.scss';

class SubtestUseStatus extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const {tests,subTestsTaken,currentTestName, roomConfig} = this.props;
		return (
		
			<span>
					{tests.map(([keyString, value], num) => {
						const key = Number(keyString);
						let extendedClassName = 'subtest-complete-notice';
						if (roomConfig.currentTest === key) {
						if(this.props.subTestsTaken && this.props.subTestsTaken.includes(value)){
							extendedClassName += `__left${num}`;
						}
					}

				return(
					<span>
						{(subTestsTaken.includes(currentTestName) && roomConfig.currentTest === key) && (
						<span className={extendedClassName}>Fullført</span>
			
				)}
					</span>
				)
				
				})}
				
			</span>
		);
	}
}

export default SubtestUseStatus;
