export const no = {
	helloWorld: 'Hallo Verden',
	childTest: 'Velkommen til Dysmate dysleksitest',
	welcomeToTest: 'Velkommen til Dysmate dysleksitest',
	literateChildTest: 'Literate Test',
	youAreReady: 'Du er klar',
	ready: 'Klar',
	chooseRole: 'Velg rolle',
	chooseRoleHelp: 'Velg hvilken rolle denne enheten vil ha',
	blue: 'blå',
	green: 'grønn',
	pink: 'rosa',
	yellow: 'gul',
	red: 'rød',
	violet: 'lilla',
	orange: 'oransje',
	gray: 'grå',
	grey: 'grå',
	bike: 'sykkel',
	teddy: 'bamse',
	apple: 'eple',
	sweater: 'genser',
	thumb: 'tommel',
	unknown: 'unknown',
	testadministrator: 'Testadministrator',
	testcandidate: 'Testkandidat',
	testkey: 'Testnøkkel',
	emailaddress: 'E-post adresse',
	starttest: 'Start test',
	candidatewelcometext:'Velkommen til Dysmate oppfølgingstest!',
	goodluck: 'Denne testen består av fire deler. Lykke til!',
	candidateInstructions:
		'Skriv inn testnøkkel som du får fra testadministrator.',
	gototest: 'Gå til testen',
	jumptotest: 'Hopp til test',
	roomkey: 'Testnøkkel: {0}',
	waitforadminstart: 'Vent til testadministrator starter testen',
	done: 'Ferdig',
	name: 'Navn',
	sound: 'Lyd',
	//intro
	helpBox1:
		'For å starte testen må du fylle inn all informasjon under, og deretter koble til en ekstra enhet ved å følge	instruksene under.',
	helpBoxList1: 'Åpne denne siden på enda en enhet.',
	helpBoxList2: 'Velg "Testkandidat"',
	helpBoxList3: 'Oppgi testnøkkel ({0})',
	helpBoxList4: 'Klikk "Gå til testen"',
	helpBoxList5:
		'Når dere er klare, trykk "Start test" nederst på denne siden',
	infoAboutCandidate: 'Informasjon om testkandidaten:',
	generalInformation: 'Generell informasjon',
	notaBene: 'NB!',
	candidateReminder:
		'{0} Husk at det er kandidaten sin informasjon som skal fylles inn, ikke din',
	firstName: 'Fornavn',
	lastName: 'Etternavn',
	birthDate: 'Fødselsdato',
	gender: 'Kjønn',
	pickOne: 'Velg',
	boy: 'Gutt',
	girl: 'Jente',
	unspecified: 'Annet',
	education: 'Utdanning',
	municipality: 'Kommune',
	county: 'Fylke',
	school: 'Skole',
	educationLevel: 'Utdanningsnivå:',
	kindergarten: 'Førskole og 1.klasse',
	primarySchool: 'Barneskole (2. - 7.klasse)',
	juniorHighSchool: 'Ungdomsskole',
	highSchool: 'Videregående skole',
	university: 'Universitet / Høyskole',
	other: 'Annet',
	pickYear: 'Velg år',
	pickClass: 'Velg klasse',
	pickLevel: 'Velg nivå',
	pick: 'Velg',
	lastYearBeforeSchool: 'Siste år før skolestart',
	class: 'Klassetrinn',
	classNum: '{0}. klasse',
	pickStudyType: 'Velg studieretning',
	specialization: 'Studiespesialisering',
	vocational: 'Yrkesfag',
	bachelor: 'Bachelor',
	master: 'Mastergrad',
	phd: 'Doktorgrad (PhD)',
	specify: 'Spesifiser',
	bilinguagualHomeQuestion: 'Har vokst opp i ett- eller flerspråklig hjem?',
	unilingual: 'Ettspråklig',
	bilingual: 'Tospråklig',
	multilingual: 'Flerspråklig',
	iConsent: 'Jeg samtykker',
	consentText:
		'Som Testadministrator så bekrefter du at du har nødvendig samtykke fra Testkandiaten til å innhente personopplysninger og at disse brukes som beskrevet',
	literatePrivacyPolicy: 'Literates personvernerklæring',
	practiceTasks: 'Øvingsoppgaver',
	startPracticeTasks: 'Start øvingsoppgaver',
	listenToInstructions: 'Lytt til instruksjonene.',
	waitingForCandidate: 'Venter på testkandidat',
	//Login
	logIn: 'Logg inn',
	loginWithBankId: 'Logg inn med BankID ved å trykke på en av knappene under',
	thisIsForTestAdmins:
		'Dette er for Testadministrator (dvs. sertifiserte brukere med abonnement)',
	bankId: 'BankID',
	bankIdMobile: 'BankID på Mobil',
	pauseBeforeNextTest: 'Pause før neste test',
	max2Listens: 'Maks to avspillinger per lydspor',
	timeUsed: 'Tid brukt: {0}',
	start: 'Start',
	pause: 'Pause',
	stop: 'Stopp',
	reset: 'Tilbakestill',
	testIsFinished: 'Testen er nå ferdig!',
	testFinishedInstruction:
		'Svarene er lagret, og du kan nå trygt lukke denne siden eller starte en ny test.',
	closeTest: 'Lukk test',
	//Testadmin:
	hotkeyDescription:
		'Marker rett eller galt ved å klikke på knappene under eller bruk tastatursnarveiene:',
	hotkeyCorrect: 'A: Korrekt',
	hotkeyWrong: 'S: Feil',
	hotkeyButtonCorrect: 'Korrekt (A)',
	buttonCorrect:'Korrekt',
	buttonWrong:'Feil',
	buttonUnanswered:'Ubesvart',
	hotkeyButtonWrong: 'Feil (S)',
	hotkeyButtonUnanswered: 'Ubesvart (D)',
	taskNum: 'Oppgave: {0}',
	taskNumOf: 'Oppgave {0} av {1}',
	correctAnswer: 'Korrekt svar: {0}',
	nextTest: 'Neste test',
	correctAnswerGiven: 'Riktig svar gitt',
	expectedAnswer: 'Forventet svar: {0}',
	clickStartWhenReady: 'Klikk "start testen" når dere er klar',
	clickStartWhenReadyEmpty:'',
	testDone:
		'{0} er nå ferdig. Klikk på "Gå til neste test" når dere er klare.',
	testName_LetterKnowledge: 'Bokstavkunnskap',
	testName_PhonemeIsolation: 'Fonemisolasjon',
	testName_WorkingMemory: 'Arbeidsminne',
	testName_WorkingMemoryNumbers: 'Arbeidsminne: Tall',
	testName_WorkingMemoryWords: 'Arbeidsminne: Ord',
	testName_RapidNaming: 'Hurtigbenevning',
	testName_PhonemeDeletion: 'Fonemutelatelse',
	testName_OneMinute: 'Ettminuttstest',
	testName_Spoonerism: 'Spoonerism',
	testName_WordDictation: 'Orddiktat',
	testName_TestComplete: 'Testen er Ferdig',
	//LetterKnowledge
	lk_testname: 'Bokstavkunnskapstesten',
	lk_adminIntro1:
		'I denne testen vil kandidaten få opp rekker med bokstaver. Kandidaten sin oppgave er å lese opp disse bokstavene i rekkefølge. Alt du skal gjøre er å svare om bokstavlyden kandidaten sier stemmer med det som står på din skjerm.',
	lk_admin1:
		'Testkandidaten vil få opp en rekke bokstaver på skjermen sin som må leses opp så nøyaktig som mulig.',
	lk_admin2:
		'Du må følge med på hvilke bokstaver som leses opp av kandidaten og svare om han har sagt korrekt eller feil i forhold til korrekt svar som står under her.',
	lk_intro:
		'Du skal nå få se noen bokstaver, og så skal du fortelle meg hvilke bokstaver du kan. Jeg vil gjerne vite om du både kan navnet på bokstaven og den lyden som bokstavene sier.',
	//OneMinute
	om_testname: '1-minuttstesten',
	om_admin1:
		'Kandidaten vil få opp rekker med ord på skjermen sin som må leses opp så raskt og nøyaktig som han greier. Du må følge med på hvilke ord som leses opp og svare om riktig ord er sagt.',
	om_admin2:
		'Du må følge med på hvilke ord som leses opp av kandidaten og svare riktig eller galt.',
	om_intro:
		'I den neste testen skal du lese så mange ord du greier på ett minutt.',
	//PhonemeDeletion
	pd_testname: 'Fonemutelatelsestesten',
	pd_admin1:
		'Testkandidaten får lest opp instruksjoner gjennom øvingsoppgavene. Du skal følge med at riktig svar blir gitt. Dersom svaret er feil må du korrigere kandidaten.',
	pd_admin2:
		'Lytt til testkandidaten og hør om de sier riktig svar som står under. Eleven vil få oppgaver som "Kan du si ris uten /r/". Nedenfor viser vi både det opprinnelige ordet, og det forventede svaret.',
	pd_intro1:
		'I denne testen vil jeg be deg si noen ord og deretter spørre deg hvilket ord som blir igjen når vi tar bort en bestemt lyd. For eksempel, om vi tar bort /s/ fra stall får vi ordet tall.',
	pd_intro2: 'Før testen starter vi med noen øvingsoppgaver.',
	pd_introDone:
		'Veldig bra! Du klarte øvingsoppgavene. Når du er klar til å starte testen kan du trykke på den blå knappen.',
	pd_testInstruction: 'Lytt til oppgaven og ta bort lyden som instruert',
	//PhonemeIsolation
	pi_testname: 'Fonemisolasjonstesten',
	pi_admin1: 'Hensikten med denne testen er å kartlegge fonembevissthet.',
	pi_admin2:
		'Kandidaten vil få se noen bilder hvor de skal klikke på det bildet som viser "ordet som begynner på ..." eller "ordet som slutter på ..."',
	candidateAnswersSelf:
		'I denne testen vil testkandidaten svare selv på sin skjerm.',
	pi_admin4: 'Vent til kandidaten har utført alle {0} oppgavene',
	pi_intro1:
		'Du skal nå få se noen bilder, og så skal du peke på det ordet som begynner på, eller slutter på, en bestemt lyd',
	pi_intro2: 'Veldig bra, du klarte øvingsoppgavene!',
	pi_intro3: 'Klikk på knappen under når du er klar til å starte testen.',
	pi_done: 'Fonemisolasjonstesten er ferdig',
	//RapidNaming
	rn_testname: 'Hurtigbenevningstesten',
	rn_admin1:
		'Denne testen er ment for å måle hvor hurtig og nøyaktig kandidaten kan lese opp en rekke bilder med farger som kommer opp på skjermen',
	rn_admin2:
		'I denne testen skal kandidaten få se noen bilder som den skal si så fort og så nøyaktig som den kan. Vi skal først se på fargene som blir brukt, og så skal vi se på tingene. Først skal vi gå gjennom noen øvingsoppgaver, klikk på den blå knappen for å begynne øvingsoppgavene.',
	rn_admin3:
		'I den første øvingsoppgaven skal kandidaten lese opp noen farger som vises på skjermen sin. Din oppgave er å følge med på hvilke farger som leses opp.',
	rn_adjustIfNotCorrect:
		'Hvis disse ikke stemmer må du korrigere kandidaten nå. Om alle er rett kan du gå videre med knappen under',
	rn_colors: 'Rød - Blå - Grønn - Grå - Oransje',
	rn_objects: 'tommel - eple - sykkel - genser - bamse',
	rn_objectsWithColors:
		'Blå tommel - Oransje Eple - Grønn sykkel - Rød genser - Grå bamse',
	rn_correctColors: 'Korrekte farger gitt',
	rn_correctItems: 'Korrekte ord gitt',
	rn_admin4:
		'I denne øvingsoppgaven vil kandidaten lese opp noen ting som vises på skjermen sin. Din oppgave er å følge med at kandidaten leser opp riktige ting, i rekkefølge.',
	rn_admin5:
		'Nå skal vi kombinere de tidligere oppgaven, og kandidaten vil bli instruert til å lese opp både fargen og tingen på bildene som vises.',
	rn_admin6:
		'Du må følge med på hvilke ord og farger som leses opp av kandidaten.',
	rn_admin7:
		'Det var alle øvingsoppgavene for denne testen. Klikk start testen når dere er klare.',
	rn_admin8:
		'Testkandidaten vil få opp en rekke bilder på skjermen sin som han må lese opp så raskt og nøyaktig som han greier.',
	rn_admin9:
		'Du må følge med på hvilke farger og ord som lese opp av kandidaten og svare om han har sagt korrekt eller feil ord.',
	rn_timeUsed: 'Tid brukt: {0} sekunder',
	rn_intro1:
		'Du skal nå få se noen bilder som du skal si så fort og så nøyaktig som du kan. Vi skal først se på fargene som blir brukt, og så skal vi se på tingene. La oss ta noen øvingsoppgaver.',
	rn_intro2:
		'Hør godt etter når jeg sier navnet på fargene rød – blå – grønn – grå – oransje. Nå er det din tur til å si fargene så fort du kan.',
	rn_intro3:
		'Hør nå godt etter når jeg sier navnet på tingene tommel - eple - sykkel - genser - bamse. Nå er det din tur til å si tingene så fort du kan.',
	rn_intro4:
		'Nå skal du få se et bilde med både fargene og tingene. Hør godt etter når jeg sier dem. Nå er det din tur til å si fargene og tingene så fort du kan',
	rn_intro5:
		'På det neste bildet vil du få se disse fargene og tingene flere ganger. Du skal si ordene bortover linja, så fort og nøyaktig som du kan, uten å hoppe over noen. Trykk på startknappen når du er klar',
	//Spoonerism
	sp_testname: 'Spoonerismtesten',
	sp_admin1:
		'I denne testen skal testkandidaten få høre to og to ord, så skal den første lyden byttes i de to ordene sånn at det lages to nye ord eller tulleord. For eksempel, ordene {0}.',
	sp_example1: 'Gul og Sol blir Sul og Gol',
	sp_1correct: '1 Korrekt (S)',
	sp_2correct: '2 Korrekt (A)',
	sp_bothWrong: 'Begge feil (D)',
	sp_admin2:
		'Testkandidaten vil få lest opp noen ord som skal leses opp med én lyd byttet mellom seg. Du skal da lytte etter om ordene som blir gjengitt samsvarer med det som står på din skjerm.',
	sp_admin3:
		'Lytt til testkandidaten og hør om de sier riktig ord som det som står på skjermen.',
	sp_instruction:
		'Lytt til oppgaven, og deretter bytt den første lyden i de to ordene',
	sp_intro1:
		'I denne testen skal du få høre to og to ord, og så skal du bytte den første lyden i de to ordene sånn at du lager to nye ord eller tulleord. For eksempel, ordene {0} blir Sul og Gol. Husk at {1} du skal bytte – det er den {2}.',
	sp_intro_reminder: 'det ikke er bokstaven',
	sp_intro3:
		'Vi er nå ferdig med øvingsoppgavene. Når du nå klikker på start knappen, starter testen. Du skal da løse oppgavene så fort du greier. Jeg vil kun lese opp ordene, så skal du si riktige svar tilbake.',
	//WordDictation
	wd_testname: 'Orddiktattesten',
	wd_intro:
		'Jeg skal nå si noen ord som du skal skrive. Hvert ord blir presentert i en setning, og så gjentar jeg det ordet du skal skrive.',
	wd_writeHere: 'Skriv ordet her',
	//WorkingMemory
	ws_testname: 'Arbeidsminnetesten',
	wsn_testname: 'Arbeidsminnetesten: Tall',
	wsw_testname: 'Arbeidsminnetesten: Ord',
	ws_admin1:
		'Denne testen er ment for å måle arbeidsminne kapasiteten hos kandidaten.',
	wsn_admin2:
		'Testkandidaten vil få lest opp noen tall som skal leses opp i motsatt rekkefølge. Du skal da lytte etter om tallene som blir gjengitt samsvarer med det som står på din skjerm.',
	wsw_admin2:
		'Testkandidaten vil få lest opp noen ord som skal leses opp i motsatt rekkefølge. Du skal da lytte etter om ordene som blir gjengitt samsvarer med det som står på din skjerm.',
	wsn_admin3:
		'Lytt til testkandidaten og hør om de sier riktig tallrekke som det som står på skjermen.',
	wsw_admin3:
		'Lytt til testkandidaten og hør om de sier riktig ordrekke som det som står på skjermen.',
	wsn_intro1:
		'Jeg skal nå si noen tall til deg. Du må høre godt etter, for når jeg er ferdig skal du si dem i omvendt rekkefølge enn jeg sier dem.',
	wsn_intro2: 'For eksempel, hvis jeg sier 1 - 3 så skal du si 3 - 1',
	wsw_intro1:
		'Jeg skal nå si noen ord til deg. Du må høre godt etter, for når jeg er ferdig skal du si dem i omvendt rekkefølge enn jeg sier dem.',
	wsw_intro2:
		'For eksempel, hvis jeg sier "bil - ball" skal du si "ball - bil"',
	wsn_test1: 'Lytt til tallene og repeter de i motsatt rekkefølge.',
	wsw_test1: 'Lytt til ordene og repeter de i motsatt rekkefølge.',
	warning1: 'Advarsel 1 - ',
	warning1text:
		'Det ser ut til at testen med denne testnøkkelen ikke er riktig startet ennå. Testadministrator må starte riktig test fra ',
	myTests: 'Mine tester',
	warning2: 'Advarsel 2',
	warning2text:
		' - Det ser ut til at du har deaktivert sporing på tvers av nettsteder i nettleseren din. Dette er nødvendig for å bruke Dysmate dysleksitester. ',
	linkForInstructions: ' Her',
	warning_text_end: ' er en oppskrift for hvordan du skrur dette på.',
	warning3: 'Advarsel 3:',
	warning3text:" {0} -  Dette er ikke gyldig. For å starte testen må du oppgi en gyldig testnøkkel. Testnøkkelen vil typisk være en 8-sifret kode bestående av tall og bokstaver.",
};

export default no;
