import React, {Fragment} from 'react';

import {getSpoonerism} from 'constants/tests.js';
import StopWatch from 'components/StopWatch';
import AdminTestPanel from 'components/AdminTestPanel';
import AdminReadyButton from 'components/AdminReadyButton';
import {updateStepData} from 'api';
import strings from 'strings';
import CountDown from 'components/CountDown';

class SpoonerismAdmin extends AdminTestPanel {
	constructor(props) {
		super(props, {
			stopOnConsecutiveErrors: true,
			maxConsecutiveErrors: 5,
			enableHotkeys: true,
		});
		this.state = {
			...this.state,
			key: 1,
		};
	}

	getTestName = () => {
		return strings.sp_testname;
	};
	updateTimeUsed = (timeUsed) => {
		let skipTime = this.state.skipTime;
		if (skipTime == timeUsed) {
			const newSkipTime = 30 + timeUsed;
			this.setState({
				timeElapsedLasAnsnwer: timeUsed,
				timeUsed: timeUsed,
				skipTime: newSkipTime,
			});
			this.answerWrong();
		} else
			this.setState({
				timeUsed: timeUsed,
				timeElapsedLasAnsnwer: timeUsed,
			});
	};

	handleKeyPress = (event) => {
		const {
			options: {limitHotkeysToTest, enableHotkeys},
			currentStage,
		} = this.state;

		if ((!limitHotkeysToTest || currentStage === 0) && enableHotkeys) {
			if (event.key === 'a') {
				this.answer(2);
			}
			if (event.key === 's') {
				this.answer(1);
			}
			if (event.key === 'd') {
				this.answer(0);
			}
		} else if (
			(!limitHotkeysToTest || currentStage === 1) &&
			enableHotkeys
		) {
			if (
				event.key === 'a' &&
				this.state.timeElapsedLasAnsnwer + 30 !== this.state.skipTime
			) {
				const skipTime = 30 + this.state.timeElapsedLasAnsnwer;
				this.setState({skipTime});
				this.answerCorrect(2);
				this.handleCountdownComplete();
			}
			if (
				event.key === 's' &&
				this.state.timeElapsedLasAnsnwer + 30 !== this.state.skipTime
			) {
				const skipTime = 30 + this.state.timeElapsedLasAnsnwer;
				this.setState({skipTime});
				this.answerCorrect(1);
				this.handleCountdownComplete();
			}
			if (
				event.key === 'd' &&
				this.state.timeElapsedLasAnsnwer + 30 !== this.state.skipTime
			) {
				const skipTime = 30 + this.state.timeElapsedLasAnsnwer;
				this.setState({skipTime});
				this.answerWrong();
				this.handleCountdownComplete();
			}
		}
	};

	answer = (numberOfCorrect) => {
		if (numberOfCorrect === 2) {
			updateStepData({
				answered: true,
				correct: true,
				feedbackDone: false,
			});
		} else {
			updateStepData({
				answered: true,
				correct: false,
				feedbackDone: false,
			});
		}
	};

	componentWillReceiveProps(newProps) {
		super.componentWillReceiveProps(newProps);

		if (
			newProps.stepData.feedbackDone &&
			!this.props.stepData.feedbackDone &&
			newProps.stepData.answered
		) {
			this.answerCorrect();
		}
	}

	getTask = (currentStep) => {
		return getSpoonerism().getIntroSteps()[currentStep].task;
	};
	getjoinedCorrect = (currentStep) => {
		return getSpoonerism().getIntroSteps()[currentStep].correct.join(' - ');
	};

	handleCountdownComplete = () => {
		this.setState((prevState) => ({
			key: prevState.key < 20 ? prevState.key + 1 : 1,
		}));
	};

	renderIntro = () => {
		const {currentStep, candidateReady} = this.state;
		return (
			<Fragment>
				{currentStep === 0 && (
					<Fragment>
						<p>
							{strings.formatString(
								strings.sp_admin1,
								<i>{strings.sp_example1}</i>
							)}
						</p>
						<AdminReadyButton
							isPractiseTasks
							remoteIsReady={candidateReady}
							onClick={this.props.nextStep}
						/>
					</Fragment>
				)}
				{currentStep > 0 && currentStep < 4 && (
					<Fragment>
						<p>{strings.practiceTasks}</p>
						<p>
							{strings.formatString(
								strings.taskNum,
								this.getTask(currentStep)
							)}
						</p>
						<p>
							{strings.formatString(
								strings.expectedAnswer,
								this.getjoinedCorrect(currentStep)
							)}
						</p>
						<button
							disabled={this.props.stepData.answered}
							onClick={(e) => {
								e.stopPropagation();
								this.answer(2);
							}}
						>
							{strings.sp_2correct}
						</button>
						<button
							disabled={this.props.stepData.answered}
							onClick={(e) => {
								e.stopPropagation();
								this.answer(1);
							}}
						>
							{strings.sp_1correct}
						</button>
						<button
							disabled={this.props.stepData.answered}
							onClick={(e) => {
								e.stopPropagation();
								this.answer(0);
							}}
						>
							{strings.sp_bothWrong}
						</button>
					</Fragment>
				)}
				{currentStep === 4 && (
					<Fragment>
						<p>{strings.sp_admin2}</p>
						<p>{strings.clickStartWhenReady}</p>
						<AdminReadyButton
							remoteIsReady={candidateReady}
							onClick={this.props.nextStage}
						/>
					</Fragment>
				)}
			</Fragment>
		);
	};

	renderTest = () => {
		const {currentStep} = this.state;
		const steps = getSpoonerism().getSteps();
		return (
			<div>
				<div className="lit-stop-watch-div">
					<StopWatch autoStart integer onTick={this.updateTimeUsed} />
				</div>
				<p>
					<br />
					Lytt til testkandidaten og hør om de sier riktig ord som det
					som står på skjermen.
					<br />
					{strings.hotkeyDescription}
					<br />
					{strings.hotkeyCorrect} <br />
					{strings.hotkeyWrong}
				</p>
				<p>
					{strings.formatString(
						strings.taskNumOf,
						currentStep + 1,
						steps.length
					)}
				</p>
				<p>
					{strings.formatString(
						strings.taskNum,
						steps[currentStep].task
					)}
				</p>
				<p>
					{strings.formatString(
						strings.expectedAnswer,
						<b>{steps[currentStep].correct.join(' - ')}</b>
					)}
				</p>
				<div className="lit-all-buttons-div">
					<button
						className="lit-correct-button-div"
						disabled={
							this.state.timeElapsedLasAnsnwer + 30 ===
							this.state.skipTime
						}
						onClick={(e) => {
							e.stopPropagation();
							const skipTime =
								30 + this.state.timeElapsedLasAnsnwer;
							this.setState({skipTime});
							this.answerCorrect(2);
							this.handleCountdownComplete();
						}}
					>
						{strings.sp_2correct}
					</button>
					<button
						disabled={
							this.state.timeElapsedLasAnsnwer + 30 ===
							this.state.skipTime
						}
						onClick={(e) => {
							e.stopPropagation();
							const skipTime =
								30 + this.state.timeElapsedLasAnsnwer;
							this.setState({skipTime});
							this.answerCorrect(1);
							this.handleCountdownComplete();
						}}
					>
						{strings.sp_1correct}
					</button>
					<button
						disabled={
							this.state.timeElapsedLasAnsnwer + 30 ===
							this.state.skipTime
						}
						onClick={(e) => {
							e.stopPropagation();
							const skipTime =
								30 + this.state.timeElapsedLasAnsnwer;
							this.setState({skipTime});
							this.answerWrong();
							this.handleCountdownComplete();
						}}
					>
						{strings.sp_bothWrong}
					</button>
					<div className="lit-count-down-div">
						{this.state.timeElapsedLasAnsnwer + 30 !==
							this.state.skipTime && (
							<CountDown
								key={this.state.key}
								duration={30}
								onComplete={this.handleCountdownComplete}
							/>
						)}
					</div>
				</div>
			</div>
		);
	};

	renderWaiting = () => {
		return (
			<div>
				<p>
					{strings.formatString(
						strings.testDone,
						strings.sp_testname
					)}
				</p>
				<Fragment>
					<button onClick={this.props.nextStage}>
						{strings.nextTest}
					</button>
				</Fragment>
			</div>
		);
	};
}

export default SpoonerismAdmin;
