import React, {Component} from 'react';
import ferdigLyd from 'assets/sounds/no/intros/testen-er-ferdig.mp3';
import AudioPlayer from 'components/AudioPlayer';

import './styles.scss';
import strings from 'strings';

class TestComplete extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const isCandidate = this.props.isCandidate || false;

		return (
			<div className="TestComplete">
				<h2>
					{isCandidate && (
						<AudioPlayer autoplay inline src={ferdigLyd} />
					)}
					{strings.testIsFinished}
				</h2>
				<p>{strings.testFinishedInstruction}</p>
				<button
					onClick={() => {
						window.localStorage.removeItem('authtoken-child');
						setTimeout(function () {
							window.location = '/';
						});
					}}
				>
					{strings.closeTest}
				</button>
			</div>
		);
	}
}

export default TestComplete;
