import React, {Component} from 'react';

import ReadyButton from 'components/ReadyButton';
import AudioPlayer from 'components/AudioPlayer';

import no_workingMemoryNumbersIntro from 'assets/sounds/no/intros/workingmemory-numbers-intro.mp3';
import nn_workingMemoryNumbersIntro from 'assets/sounds/nn/intros/workingmemory-numbers-intro.mp3';
import strings from 'strings';

class Intro extends Component {
	constructor(props) {
		super(props);
		switch (strings.getLanguage()) {
			case 'nn':
				this.audio = nn_workingMemoryNumbersIntro;
				break;
			case 'no':
			default:
				this.audio = no_workingMemoryNumbersIntro;
				break;
		}
	}

	render() {
		return (
			<>
				<p>
					<AudioPlayer autoplay inline src={this.audio} />
					{strings.wsn_intro1}
					<br />
					{strings.wsn_intro2}
				</p>
				<ReadyButton onReadyChange={this.props.setReady} />
			</>
		);
	}
}

export default Intro;
