import no_intro1 from 'assets/sounds/no/rapidNaming/rapidNaming-intro1.mp3';
import no_intro2 from 'assets/sounds/no/rapidNaming/rapidNaming-intro2.mp3';
import no_intro3 from 'assets/sounds/no/rapidNaming/rapidNaming-intro3.mp3';
import no_intro4 from 'assets/sounds/no/rapidNaming/rapidNaming-intro4.mp3';
import no_intro5 from 'assets/sounds/no/rapidNaming/rapidNaming-intro5.mp3';

import nn_intro1 from 'assets/sounds/nn/rapidNaming/rapidNaming-intro1.mp3';
import nn_intro2 from 'assets/sounds/nn/rapidNaming/rapidNaming-intro2.mp3';
import nn_intro3 from 'assets/sounds/nn/rapidNaming/rapidNaming-intro3.mp3';
import nn_intro4 from 'assets/sounds/nn/rapidNaming/rapidNaming-intro4.mp3';
import nn_intro5 from 'assets/sounds/nn/rapidNaming/rapidNaming-intro5.mp3';

export const no = {
	intro1: no_intro1,
	intro2: no_intro2,
	intro3: no_intro3,
	intro4: no_intro4,
	intro5: no_intro5,
};

export const nn = {
	intro1: nn_intro1,
	intro2: nn_intro2,
	intro3: nn_intro3,
	intro4: nn_intro4,
	intro5: nn_intro5,
};
