import React, {Component, Fragment} from 'react';

import ReadyButton from 'components/ReadyButton';
import AudioPlayer from 'components/AudioPlayer';

import letterKnowledgeIntroNo from 'assets/sounds/no/intros/letterKnowledge-intro.mp3';
import letterKnowledgeIntroNn from 'assets/sounds/nn/intros/letterKnowledge-intro.mp3';
import strings from 'strings';

class Intro extends Component {
	constructor(props) {
		super(props);

		switch (strings.getLanguage()) {
			case 'nn':
				this.letterKnowledgeIntro = letterKnowledgeIntroNn;
				break;
			case 'no':
			default:
				this.letterKnowledgeIntro = letterKnowledgeIntroNo;
				break;
		}
	}

	render() {
		return (
			<Fragment>
				<p>
					<AudioPlayer
						autoplay
						inline
						src={this.letterKnowledgeIntro}
					/>
					{strings.lk_intro}
				</p>
				<ReadyButton onReadyChange={this.props.setReady} />
			</Fragment>
		);
	}
}

export default Intro;
