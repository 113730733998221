import strings from 'strings';
import * as no from 'assets/testAnswers/no';
import * as nn from 'assets/testAnswers/nn';

export const getTestLanguage = () => {
	switch (strings.getLanguage()) {
		case 'nn':
			return nn;

		case 'no':
			return no;
		default:
			return no;
	}
};

export const getWorkingMemory = () => ({
	stages: [
		'Intro',
		'Trial',
		'Pause',
		'numbers',
		'Pause',
		'Intro',
		'Trial',
		'Pause',
		'words',
		'Pause',
	],
	getNumberSteps: () => {
		return getTestLanguage().WMNAnswers;
	},
	getWordSteps: () => {
		return getTestLanguage().WMWAnswers;
	},
	getIntroSteps: () => {
		return [];
	},
	getNumberTrialSteps: () => {
		return getTestLanguage().WMNTrialAnswers;
	},
	getWordTrialSteps: () => {
		return getTestLanguage().WMWTrialAnswers;
	},
});

export const getSpoonerism = () => ({
	stages: ['Intro', 'Test', 'Pause'],
	getSteps: () => {
		return getTestLanguage().SpoonerismAnswers;
	},
	getIntroSteps: () => {
		return ['intro', ...getTestLanguage().SpoonerismIntroTasks, 'summary'];
	},
});

export const getRapidNaming = (ageGroup) => ({
	stages: ['Intro', 'Test', 'Pause'],
	getSteps: () => {
		return ageGroup == 0 || ageGroup == 1
			? [].concat(...getTestLanguage().kdrnAnswers)
			: [].concat(...getTestLanguage().rnAnswers);
	},
	getAnswers: () => {
		return ageGroup == 0 || ageGroup == 1
			? getTestLanguage().kdrnAnswers
			: getTestLanguage().rnAnswers;
	},
	getIntroSteps: () => {
		return getTestLanguage().rnIntroSteps;
	},
});

export const getPhonemeIsolation = (testKeyType, ageGroup) => ({
	stages: ['Intro', 'Test', 'Pause'],
	getSteps: () => {
		return getTestLanguage().phonemeIsolationNormingTasks;
	},
	getIntroSteps: () => {
		return getTestLanguage().phonemeIsolationIntro;
	},
});

export const getOneMinute = () => ({
	stages: ['Intro', 'Test', 'Pause'],
	getSteps: () => {
		return getTestLanguage().oneMinuteTasks;
	},
	getIntroSteps: () => {
		return [];
	},
});

export const getLetterKnowledge = (testKeyType, ageGroup) => ({
	stages: ['Intro', 'Test', 'Pause'],
	getSteps: () => {
		return testKeyType === 'youth-followup' ||
			testKeyType === 'adult-followup'
			? getTestLanguage().lkAnswersNorming
			: ageGroup == 0 || ageGroup == 1
			? getTestLanguage().kdlkAnswers
			: getTestLanguage().lkAnswers;
	},
	getIntroSteps: () => {
		return [];
	},
});

export const getPhonemeDeletion = () => ({
	stages: ['Intro', 'Test', 'Pause'],
	getSteps: () => {
		return getTestLanguage().pdSteps.slice();
	},
	getAnswers: () => {
		return getTestLanguage().pdAnswers;
	},
	getIntroSteps: () => {
		return ['intro', ...getTestLanguage().pdIntroSteps, 'outro'];
	},
});

export const getWordDictation = (testKeyType) => ({
	//Word Dictation
	stages: ['Intro', 'Test', 'Pause'],
	getSteps: () => {
		return testKeyType === 'youth-followup' ||
			testKeyType === 'adult-followup'
			? getTestLanguage().wdAnswersNorming.slice()
			: getTestLanguage().wdAnswers.slice();
	},
	getIntroSteps: () => {
		return [];
	},
});

export const tests = {
	LetterKnowledge: getLetterKnowledge,
	WorkingMemory: getWorkingMemory,
	RapidNaming: getRapidNaming,
	PhonemeIsolation: getPhonemeIsolation,
	PhonemeDeletion: getPhonemeDeletion,
	OneMinute: getOneMinute,
	WordDictation: getWordDictation,
	Spoonerism: getSpoonerism,
};
export default tests;
