import React, {Component} from 'react';

import './styles.scss';

import Intro from './Intro';
import Test from './Test';
import Pause from 'components/Pause';
import strings from 'strings';

const subSteps = [Intro, Test, Pause];

class WordDictation extends Component {
	constructor() {
		super();

		this.state = {
			currentStage: 0,
			currentStep: 0,
		};
	}

	componentWillReceiveProps(newProps) {
		const newState = {};
		let flag = false;
		if (newProps.currentStep !== this.props.currentStep) {
			newState.currentStep = newProps.currentStep;
			flag = true;
		}
		if (newProps.currentStage !== this.props.currentStage) {
			newState.currentStage = newProps.currentStage;
			flag = true;
		}
		if (flag) {
			this.setState(newState);
		}
	}

	render() {
		const {currentStage} = this.state;

		const CurrentSubStep = subSteps[currentStage];

		return (
			<div className="WordDictation">
				<h1 className="lit-page-title">{strings.wd_testname}</h1>
				<CurrentSubStep
					currentStep={this.state.currentStep}
					stepData={this.props.stepData}
					nextStep={this.props.nextStep}
					submitWordDictation={this.props.submitWordDictation}
					testKeyType={this.props.testKeyType}
					setReady={this.props.setReady}
					startTimer={this.props.startTimer}
					endTimer={this.props.endTimer}
				/>
			</div>
		);
	}
}

export default WordDictation;
