import React, {Component} from 'react';

import ReadyButton from 'components/ReadyButton';
import AudioPlayer from 'components/AudioPlayer';

import gragenser from 'assets/images/rapidNaming/gragenser.png';
import graeple from 'assets/images/rapidNaming/graeple.png';
import gratommel from 'assets/images/rapidNaming/gratommel.png';
import grasykkel from 'assets/images/rapidNaming/grasykkel.png';
import grabamse from 'assets/images/rapidNaming/grabamse.png';
import blatommel from 'assets/images/rapidNaming/blatommel.png';
import oransjeeple from 'assets/images/rapidNaming/oransjeeple.png';
import rodgenser from 'assets/images/rapidNaming/rodgenser.png';
import gronnsykkel from 'assets/images/rapidNaming/gronnsykkel.png';

import {no, nn} from './assets';

import strings from 'strings';

class Intro extends Component {
	constructor(props) {
		super(props);

		switch (strings.getLanguage()) {
			case 'nn':
				this.assets = nn;
				break;
			case 'no':
			default:
				this.assets = no;
				break;
		}

		this.state = {
			stepData: {},
			hasCorrectWordsGiven: false,
			step: 0,
		};
	}

	componentWillReceiveProps(newProps) {
		if (this.props.currentStep !== newProps.currentStep) {
			this.setState({step: newProps.currentStep});
		}
		if (this.props.stepData !== newProps.stepData) {
			const hasCorrectWordsGiven =
				newProps.stepData.hasOwnProperty('correctWordsGiven') &&
				newProps.stepData.correctWordsGiven;

			if (!this.state.hasCorrectWordsGiven && hasCorrectWordsGiven) {
				this.setState({
					hasCorrectWordsGiven,
				});
				this.playSecondClip();
			}
		}
	}

	playSecondClip = () => {
		if (this.audio2) this.audio2.handleStartPlayer();
	};

	render() {
		const step = this.state.step;
		return (
			<div className="RapidNamingIntro">
				{step === 0 && (
					<p>
						<AudioPlayer autoplay inline src={this.assets.intro1} />
						{strings.rn_intro1}
					</p>
				)}
				{step === 1 && (
					<>
						<p>
							<AudioPlayer
								autoplay
								inline
								src={this.assets.intro2}
							/>
							{strings.rn_intro2}
						</p>
						<div className="items">
							<div className="circle red" />
							<div className="circle blue" />
							<div className="circle green" />
							<div className="circle gray" />
							<div className="circle orange" />
						</div>
					</>
				)}
				{step === 2 && (
					<>
						<p>
							<AudioPlayer
								autoplay
								inline
								src={this.assets.intro3}
							/>
							{strings.intro3}
						</p>
						<div className="items">
							<img className="image" src={gratommel} alt="" />
							<img className="image" src={graeple} alt="" />
							<img className="image" src={grasykkel} alt="" />
							<img className="image" src={gragenser} alt="" />
							<img className="image" src={grabamse} alt="" />
						</div>
					</>
				)}
				{step === 3 && (
					<>
						<p>
							<AudioPlayer
								autoplay
								inline
								src={this.assets.intro4}
							/>
							{strings.intro4}
						</p>
						<div className="items">
							<img className="image" src={blatommel} alt="" />
							<img className="image" src={oransjeeple} alt="" />
							<img className="image" src={gronnsykkel} alt="" />
							<img className="image" src={rodgenser} alt="" />
							<img className="image" src={grabamse} alt="" />
						</div>
					</>
				)}
				{step === 4 && (
					<>
						<p>
							<AudioPlayer
								ref={(node) => (this.audio2 = node)}
								inline
								autoplay
								src={this.assets.intro5}
							/>
							{strings.rn_intro5}
						</p>
						<ReadyButton onReadyChange={this.props.setReady} />
					</>
				)}
			</div>
		);
	}
}

export default Intro;
