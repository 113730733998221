import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {setAuthTokenAction} from 'actions/auth';
import exchangeToken from '../../endpoints/exchangeToken';
import strings from 'strings';
import Login from 'components/Login';
import dysmateLogo from 'assets/images/logo-white.png';

import {CHILD_TEST_URL, SOCKET_ENDPOINT} from 'constants/index';

import queryString from 'query-string';
import {Howl} from 'howler';
import nosound from 'assets/nosound.mp3';
import './styles.scss';

const parsedSearch = queryString.parse(window.location.search);
let search = parsedSearch;
		let params = new URLSearchParams(search);
		let key = params.get('key');
		let state = params.get('state');
		let role = params.get('role');
		let testKeyType = params.get('type');
		let token = params.get('token');
var thirdPartyCookies = true;
const verifyAuth = (key, token) => {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	headers.append('Authorization', 'Bearer ' + token);

	return fetch(SOCKET_ENDPOINT + '/token/verify?key=' + key, {
		method: 'get',
		headers,
	}).then((response) => {
		if (response.ok) {
			return response.json();
		}

		return Promise.reject(response);
	});
};

class SelectRole extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentView: testKeyType ? 'testadmin':'initial',
			testKey: '',
			roomKey: '',
			redirectLink:null,
			adminEmail: '',
			testKeyType: '',
			hasEnteredCode: false,
			askForPincode:false,
			pinCode:null,
			passCodeError:null,
			userPin:'',
			continued:state,
			failStartInstruction: this.props.failStart,
			invalidKeyInstruaction:null,
			altError:null,
			instructions:null,
		};
	}

	componentWillMount = () => {
		
		let authToken = localStorage.getItem('authtoken-child');
		if (authToken) localStorage.removeItem('authtoken-child');
		if (role === 'admin') {
			this.setState({
				currentView: 'testadmin',
				testKey: key,
				testKeyType: testKeyType,
				token: token,
				hasEnteredCode: false,
			});
			if (key && token) {
				verifyAuth(key, token).then((res) => {
					console.log('authorized');
					this.props.setAuthToken(token);
					this.onAdminSubmit();
				});
			}
		} else if (role === 'candidate') {
			this.setState({
				currentView: 'candidate',
				roomKey: key,
				testKeyType: testKeyType,
			});
		}
	};

	onAdminSubmit = (e) => {
		if (e && e.preventDefault) e.preventDefault();

		const {onSelectRole} = this.props;
		const {testKey, adminEmail, testKeyType, continued} = this.state;

		onSelectRole('testadmin', testKey, testKeyType, adminEmail,continued);

		return false;
	};

	onCandidateSubmit = (e) => {
		e.preventDefault();
		const {onSelectRole} = this.props;
		const {roomKey, testKeyType} = this.state;
		if(roomKey && roomKey.length === 8){
		global.myAudio = new Howl({
			src: [nosound],
			html5: true,
			loop: true,
		}).play();
		onSelectRole('testuser', roomKey, testKeyType);
		return false;
	}
	else{
		this.setState({
			invalidKeyInstruaction:strings.formatString(strings.warning3text,roomKey)
		})
	}
	};

	render() {
		const {currentView, hasEnteredCode, testKey, testKeyType} = this.state;
		const thridPartiesCookiesEnabled = thirdPartyCookies;
		return (
			<div className="SelectRole">
				<img
					src={dysmateLogo}
					alt="Literate"
					className="title__logo"
				/>
				<div className="SelectRoleContainer">
					{currentView === 'initial' && (
						<Fragment>
							<h1 className="title">{strings.childTest}</h1>
							<h2 className="SelectRole__heading">
								{strings.chooseRole}
							</h2>
							<p>{strings.chooseRoleHelp}</p>
							<br />
							<div>
								<button
									className="SelectRole__select-button"
								
									onClick={() =>
										this.setState({
											currentView: 'testadmin',
										})
									}
								>
									{strings.testadministrator}
								</button>
								<button
									className="SelectRole__select-button"
									onClick={() =>
										this.setState({
											currentView: 'candidate',
										})
									}
								>
									{strings.testcandidate}
								</button>
							</div>
						</Fragment>
					)}
					{currentView === 'testadmin' && !testKeyType && (
						<Fragment>
							{!hasEnteredCode && !this.state.altError && (
								<Fragment>
									<h2>{strings.testadministrator}</h2>

									<form
										onSubmit={(e) => {
											e.preventDefault();

											if(!this.state.askForPincode){
												exchangeToken(testKey).then(
													( res) => {
														const requireVerification = res.requireVerification;
														const pinCode = res.pinCode;
														if(!requireVerification && res.status ==='notUsed'){
															 window.open(
															`${CHILD_TEST_URL}?key=${testKey}&role=admin&token=${res.token}&type=${res.testType}`,
															'_self'
														);
														if (
															this.props.onStartTest
														) {
															this.props.onStartTest();
														}  
														}
														else{
															let instructionsText = res.status!=='isCompleted' && res.requireVerification ?<span>Denne testen er eldre enn en måned. For å sikre ditt personvern må vi bekrefte at det er riktig bruker starter testen. Vennligst bruk pinkoden som vi har sendt til <span style={{color:'yellow'}}><i><u>{res.adminEmail}</u></i></span> for å starte testen.</span>:null;
															if (res.status==='isUsed') {
															instructionsText =<span>Denne testen er startet før, men ikke fullført. For å sikre ditt personvern må vi bekrefte at det er riktig bruker starter testen. Vennligst bruk pinkoden som vi har sendt til <span style={{color:'yellow'}}><i><u>{res.adminEmail}</u></i></span> for å starte testen.</span>;
														}
															const errorToThrow = res.status==='isCompleted' ?'Denne testen er allerede fulført':null;
															const socketLink = `${CHILD_TEST_URL}?key=${testKey}&role=admin&token=${res.token}&type=${res.testType}`
															this.setState({
																askForPincode:true,
																adminEmail:res.adminEmail,
																redirectLink:socketLink,
																pinCode:pinCode,
																altError:errorToThrow,
																instructions:instructionsText,
															})
														}
														
													}
												);
											}

											if(this.state.askForPincode && this.state.userPin) {
												if(this.state.pinCode.toLowerCase() === this.state.userPin.toLowerCase()){
													window.open(
														this.state.redirectLink,
														'_self'
													);
													if (
														this.props.onStartTest
													) {
														this.props.onStartTest();
													}
												}
												else{
													this.setState({passCodeError:'Feil Pinkode. Testet kan ikke startes'})
												}
											}
											return false;
										}}
									>
										{/* {testKeyType !== 'youth-followup' && ( */}
										<div className="SelectRole__label">
											<label htmlFor="testkey">
												{strings.testkey}
											</label>
											<input
												className="SelectRole__input"
												name="testkey"
												type="text"
												defaultValue={testKey}
												required
												placeholder="XXXXXXXX"
												onChange={(e) =>
													this.setState({
														testKey: e.target.value.trim().toUpperCase(),
													})
												}
											/>
										</div>
								{this.state.askForPincode && !this.state.altError && (
								<div>
								<div style={{width:'70%',margin:'auto'}}>
								{this.state.instructions}
								</div>
								<br></br>
								<input
									className="SelectRole__pinInput"
									name="testkey"
									type="text"
									required
									placeholder="XXXX"
									onChange={(e) =>{
										this.setState({
											userPin: e.target.value.trim(),
											passCodeError:false,
										})
									}}
								/>
								{this.state.passCodeError && (
									<div><span style={{color:'orange', fontSize:'20px'}}>{this.state.passCodeError}</span></div>
								
								)}

							</div>
							)}
										<button
											className="SelectRole__submit-button"
											type="submit"
											disabled={this.state.userPin && this.state.passCodeError}
										>
											{strings.starttest}
										</button>
									</form>
								</Fragment>
							)}
							{hasEnteredCode && (
								<Login
									onLogin={this.onAdminSubmit}
									testKey={this.state.testKey}
								/>
							)}
							
							
						</Fragment>
						
					)}
					{currentView === 'candidate' && (
						<Fragment>
							<h2>{strings.testcandidate}</h2>
							<p>{strings.candidatewelcometext}</p>
							<p>{strings.goodluck}</p>
							<p>{strings.candidateInstructions}</p>
							<form onSubmit={this.onCandidateSubmit}>
								<input
									type="text"
									required
									onChange={(e) =>{
										
										this.setState({
											roomKey: e.target.value
												.toUpperCase()
												.trim(),
										})
									
								}}
								/>
								<button type="submit" style={{marginLeft:'27px'}}>
									{strings.gototest}
								</button>
							</form>

							{this.state.failStartInstruction && (
								<span className="candidate-start-fail">
									<p
										className="candidate-start-fail__instructions"
										style={{color: 'yellow'}}
									>
										<span style={{color: 'Coral'}}>
											<b>{strings.warning1} </b>
										</span>
										<em>{strings.warning1text}</em>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://literate.no/personvernerklaering/"
										>
											<u style={{color: 'lime'}}>
												{strings.myTests}
											</u>
										</a>
									</p>
								</span>
							)}
							{this.state.invalidKeyInstruaction && (
								<span className="candidate-start-fail">
									<p
										className="candidate-start-fail__instructions"
										style={{color: 'yellow'}}
									>
										<span style={{color: 'Coral'}}>
											<b> {strings.warning3}</b>
										</span>
										<></>
										<em>{this.state.invalidKeyInstruaction}</em>
										
									</p>
								</span>
							)}
							
							
						</Fragment>
					)}
				</div>

				<div>
					<iframe
						src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
						style={{display: 'none'}}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit, isAuthenticated} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
		isAuthenticated,
	};
};

const mapDispatchToProps = {
	setAuthToken: setAuthTokenAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectRole);
