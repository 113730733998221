import React, {Component} from 'react';

import {getPhonemeIsolation} from 'constants/tests';

import './styles.scss';
import ReadyButton from 'components/ReadyButton';

import AudioPlayer from 'components/AudioPlayer';

import phonemeIsolationIntroNo from 'assets/sounds/no/intros/phonemeIsolation-starter-intro.mp3';
import klarteOppgaveneNo from 'assets/sounds/no/intros/phonemeIsolation-klarte-oppgaver.mp3';

import phonemeIsolationIntroNn from 'assets/sounds/nn/intros/phonemeIsolation-starter-intro.mp3';
import klarteOppgaveneNn from 'assets/sounds/nn/intros/phonemeIsolation-klarte-oppgaver.mp3';

import {images, getSounds, soundNames} from './assets';
import strings from 'strings';

class Intro extends Component {
	constructor(props) {
		super(props);

		const lang = strings.getLanguage();
		this.sounds = getSounds(lang);

		Object.values(this.sounds).forEach((sound) => sound.load());

		switch (strings.getLanguage()) {
			case 'nn':
				this.phonemeIsolationIntro = phonemeIsolationIntroNn;
				this.klarteOppgavene = klarteOppgaveneNn;
				break;
			case 'no':
			default:
				this.phonemeIsolationIntro = phonemeIsolationIntroNo;
				this.klarteOppgavene = klarteOppgaveneNo;
				break;
		}

		this.state = {
			isShowingPractiseTests: false,
		};
	}

	componentWillReceiveProps(newProps) {
		if (this.props.currentStep !== newProps.currentStep) {
			if (newProps.currentStep || newProps.currentStep === 0) {
				if (
					newProps.currentStep <
					getPhonemeIsolation(this.props.testKeyType).getIntroSteps()
						.length
				)
					this.playSound(
						'starts_with_' +
							getPhonemeIsolation(
								this.props.testKeyType
							).getIntroSteps()[newProps.currentStep].sound
					);
			}
		}
	}

	selectOption = (option) => {
		if (this.selectOptionTimeout) return;

		this.stopSounds();
		const introSteps = getPhonemeIsolation().getIntroSteps();
		const step = introSteps[this.props.currentStep];
		if (step.options[option] === step.correct /*Do something*/);
		this.props.nextStep();

		this.selectOptionTimeout = setTimeout(() => {
			clearTimeout(this.selectOptionTimeout);
			this.selectOptionTimeout = null;
		}, 1000);
	};

	showPractiseTests = () => {
		const introSteps = getPhonemeIsolation().getIntroSteps();
		const stepSound = introSteps[this.props.currentStep].sound;
		this.setState({
			isShowingPractiseTests: true,
		});
		this.playSound(`starts_with_${stepSound}`);
	};

	playAllSounds = () => {
		const newSoundNames = [...soundNames, 'starts_with_g', 'starts_with_h'];
		newSoundNames.forEach((soundName) => {
			this.sounds[soundName].volume(0);
			this.sounds[soundName].play();
			setTimeout(() => {
				this.sounds[soundName].stop();
				this.sounds[soundName].volume(1);
			}, 100);
		});
	};

	playSound = (sound, volume = 1) => {
		this.sounds[sound].volume(volume);
		this.sounds[sound].play();

		this.sounds[sound].onended = () => {
			console.log('START TIMER NOW');
			this.props.startTimer();
		};
	};

	stopSounds = () => {
		for (const sound of Object.values(this.sounds)) {
			sound.pause();
		}
	};

	render() {
		const introSteps = getPhonemeIsolation().getIntroSteps();
		const step = introSteps[this.props.currentStep];
		return (
			<div className="PhonemeIsolation">
				{!this.state.isShowingPractiseTests && (
					<>
						<p>
							<AudioPlayer
								autoplay
								inline
								src={this.phonemeIsolationIntro}
							/>
							{strings.pi_intro1}
						</p>
						<button onClick={this.showPractiseTests}>
							{strings.startPracticeTasks}
						</button>
					</>
				)}
				{this.state.isShowingPractiseTests && step && (
					<>
						<h3>{strings.practiceTasks}</h3>
						<p>{strings.listenToInstructions}</p>
						<div className="phoneme-options">
							{Object.entries(step.options).map(
								([optionKey, optionValue], index2) => (
									<div
										className="phoneme-option"
										onClick={() =>
											this.selectOption(optionKey)
										}
										style={{
											backgroundImage: `url(${images[optionValue]})`,
										}}
									/>
								)
							)}
						</div>
					</>
				)}
				{this.props.currentStep === introSteps.length && (
					<>
						<p>
							<AudioPlayer
								autoplay
								inline
								src={this.klarteOppgavene}
							/>
							{strings.pi_intro2}
						</p>
						<p>{strings.pi_intro3}</p>
						<ReadyButton
							onClick={this.playAllSounds}
							onReadyChange={this.props.setReady}
						/>
					</>
				)}
			</div>
		);
	}
}

export default Intro;
