import React from 'react';

import AudioPlayer from 'components/AudioPlayer';
import ReadyButton from 'components/ReadyButton';
import no_audio from 'assets/sounds/no/dictation/diktat-intro.mp3';
import nn_audio from 'assets/sounds/nn/dictation/diktat-intro.mp3';
import strings from 'strings';

class Intro extends React.Component {
	constructor() {
		super();

		switch (strings.getLanguage()) {
			case 'nn':
				this.audio = nn_audio;
				break;
			case 'no':
			default:
				this.audio = no_audio;
				break;
		}

		this.state = {};
	}

	render() {
		return (
			<div className="lit-intro">
				<p
					style={{
						marginBottom: 0,
					}}
				/>
				<p className="text-center">
					<AudioPlayer autoplay inline src={this.audio} />
					{strings.wd_intro}
				</p>
				<ReadyButton onReadyChange={this.props.setReady} />
			</div>
		);
	}
}

export default Intro;
