import React, {Component, Fragment} from 'react';
import AdminReadyButton from '../AdminReadyButton';
import {IS_NORMING_VARIANT} from 'constants';
import strings from 'strings';

class TestIntroFilled extends Component {
	constructor(props) {
		super(props);

		this.state = {
			candidateInfo: {
				gender: 'm',
				class: 0,
			},
			allowGenderSelect:false,
			allowBilingualSelect:false,
			allowEducationSelect:false,
			languageSelected: null,
			education: null,
		};
	}

	onLanguageSelect = (lang) => {
		this.props.updateLanguage(lang);
		strings.setLanguage(lang);
		this.setState({
			languageSelected: lang,
			candidateInfo:this.props.userInformation
		});
	};

	handleInputChange = (event) => {
		const target = event.target;
		const value =
			target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			candidateInfo: {
				...this.state.candidateInfo,
				[name]: value,
			},
		});
	};

	setEducation = (e) => {
		this.setState({
			education: e.target.value,
		});
	};
	returnGender(gender, testType){
		if(testType.includes('adult')){
			switch(gender){
				case'f':
				return 'Kvinne';
				case'm':
				return 'Mann';
				default:
				return 'annet';
			}
			
		}
		else{
			
			switch(gender){
				case'f':
				return 'Jente';
				case'm':
				return 'Gutt';
				default:
				return 'annet';
			}
		}
	}
	returnProperGrade(candidateInfo){
	const grade =	candidateInfo.ageGroup || candidateInfo.class;
	if(grade){
		if(['1','2','3','4','5','6','7','8','9','10'].includes(grade)){
			return strings.formatString(strings.classNum,grade)
		}
		if(['0'].includes(grade)){
			return strings.lastYearBeforeSchool;
		}
		else
			return grade
}
	else{
		this.setState({
			allowEducationSelect:true
		})
}
	}

	languageStatus(languagesInHome){
		// eslint-disable-next-line default-case
		switch(languagesInHome){
			case 'one':
				return strings.unilingual;
			case 'two':
				return strings.bilingual;
			case 'multiple':
				return strings.multilingual;
		}
	}
	handleReadyClick = (e) => {
		e.preventDefault();
		const {firstName, lastName, class: ageGroup} = this.state.candidateInfo;
		const updatedClass =
			this.state.education === 'other' ? 'other' : ageGroup;
		this.setState(
			{
				candidateInfo: {
					...this.state.candidateInfo,
					name: firstName + ' ' + lastName,
					class: updatedClass,
					testType: this.props.testKeyType,
				},
			},
			() => {
				this.props.onReadyClick(this.state.candidateInfo);
			}
		);
		return false;
	};

	render() {
		const {roomKey, isClientReady,userInformation} = this.props;
		const {languageSelected,candidateInfo} = this.state;

		if (!languageSelected) {
			return (
				<div>
					{/* <span style={{color:'yellow',fontSize:'18px'}}>Vi opplever noen utfordringer med oppstart av oppfølgingstestene i disse dager. Trykk <span><a href="https://www.dysmate.no/wp-content/uploads/2024/01/Oppfolgingstest-innlogging.pdf" target='_blank' rel='noopener norefferrer'>
						her
					</a></span> for å se hvordan du likevel kan starte oppfølgingstestene.
					
					
					</span> */}
					<h2>Velg/vel Språk</h2>
					<div className="LanguageButton_wrapper">
						<button
							tabIndex="1"
							className="LanguageButton"
							onClick={() => this.onLanguageSelect('no')}
						>
							Norsk Bokmål
						</button>
						<button
							tabIndex="2"
							className="LanguageButton"
							onClick={() => this.onLanguageSelect('nn')}
						>
							Norsk Nynorsk
						</button>
					</div>
				</div>
			);
		}

		return (
			<div className="TestIntro">
				<div className="info-box">
					<p className="info-box__title">{strings.welcomeToTest}</p>
					<p className="info-box__hint">{strings.helpBox1}</p>
					<ol>
						<li>{strings.helpBoxList1}</li>
						<li>{strings.helpBoxList2}</li>
						<li>
							{strings.formatString(
								strings.helpBoxList3,
								<b>{roomKey}</b>
							)}
						</li>
						<li>{strings.helpBoxList4}</li>
						<li>{strings.helpBoxList5}</li>
					</ol>
				</div>
				<div>
					<h2>{strings.infoAboutCandidate}</h2>
					<form onSubmit={this.handleReadyClick} className="form">
						<Fragment>
							<h2>{strings.generalInformation}</h2>
							<p className="warning">
								{strings.formatString(
									strings.candidateReminder,
									<b>{strings.notaBene}</b>
								)}
							</p>
							<br />
							<label className="inline">
								{strings.firstName}{' '}
								<input
									type="text"
									name="firstName"
									placeholder={candidateInfo.firstName}
									onChange={this.handleInputChange}
								
								/>
							</label>
							<label className="inline">
								{strings.lastName}{' '}
								<input
									type="text"
									name="lastName"
									placeholder={candidateInfo.lastName}
									onChange={this.handleInputChange}
								
								/>
							</label>
							<label>
								{strings.birthDate}
								<input
									type="text"
									name="birthdate"
									placeholder={candidateInfo.birthdate.replaceAll("-","/")}
									onChange={this.handleInputChange}
									onFocus={(e) => (e.target.type = "date")}
									onBlur={(e) => (e.target.type = "text")}
								
								/>
							</label>
							{candidateInfo.gender && !this.state.allowGenderSelect && (
							<label>
								{strings.gender}
								<input
									type="text"
									name="gender"
									placeholder={this.returnGender(candidateInfo.gender,this.props.testKeyType)}
									onChange={this.handleInputChange}
									onFocus={(e) => {this.setState({
										allowGenderSelect:true
									})}}
									onBlur={(e) => {
										this.setState({
											allowGenderSelect:false
										})
									}}
								
								/>
							</label>)}
							{this.state.allowGenderSelect && (
								<label>
								{strings.gender}
								<select
									name="gender"
									onChange={this.handleInputChange}
									onBlur={(e) => {
										this.setState({
											allowGenderSelect:false
										})
									}}
									
								>
									<option value="">{strings.pickOne}</option>
									<option value="m">{strings.boy}</option>
									<option value="f">{strings.girl}</option>
									<option value="u">
										{strings.unspecified}
									</option>
								</select>
							</label>
							)}
						</Fragment>
						<Fragment>
							<h2>{strings.education}</h2>
							<div>
								<label>
									{strings.municipality}
									<input
										onChange={this.handleInputChange}
										
										type="text"
										name="municipality"
										id="municipality"
										placeholder={candidateInfo.municipality}
									/>
								</label>
							</div>
							<div>
								<label>
									{strings.school}
									<input
										onChange={this.handleInputChange}
										
										type="text"
										name="school"
										id="school"
										placeholder={candidateInfo.school}
									/>
								</label>
							</div>
							{!this.state.allowEducationSelect && (
								<label className="checkbox-group-title">
								{strings.educationLevel}
								<input
									type="text"
									name="languagesInHome"
									placeholder={this.returnProperGrade(candidateInfo)}
								
									onFocus={(e) => {this.setState({
										allowEducationSelect:true
									})}}
									onBlur={(e) => {
										this.setState({
											allowEducationSelect:false
										})
									}}
								
								/>
							</label>
							)}
							{this.state.allowEducationSelect && (
								<div onChange={this.setEducation}>
								<label className="checkbox-group-title">
									{strings.educationLevel}
								</label>
								{(this.props.testKeyType === 'child' ||
									this.props.testKeyType ===
										'child-followup') && (
									<label>
										<input
											
											type="radio"
											name="education"
											value="barnehage"
										/>
										{strings.kindergarten}
									</label>
								)}
								{(this.props.testKeyType === 'child' ||
									this.props.testKeyType ===
										'child-followup') && (
									<label>
										<input
											
											type="radio"
											name="education"
											value="barneskole"
										/>
										{strings.primarySchool}
									</label>
								)}
								{this.props.testKeyType ===
									'youth-followup' && (
									<span>
										<label>
											<input
												
												type="radio"
												name="education"
												value="ungdomsskole"
											/>
											{strings.juniorHighSchool}
										</label>
									</span>
								)}
								{this.props.testKeyType ===
									'adult-followup' && (
									<span>
										<label>
											<input
												
												type="radio"
												name="education"
												value="videregaendeskole"
											/>
											{strings.highSchool}
										</label>
										<label>
											<input
												
												type="radio"
												name="education"
												value="universitet"
											/>
											{strings.university}
										</label>
										<label>
											<input
												
												type="radio"
												name="education"
												value="other"
											/>
											{strings.other}
										</label>
									</span>
								)}
							</div>
							)}
							<br />
							{this.state.education === 'barnehage' && (
								<label>
									{strings.pick}
									<select
										
										name="class"
										onChange={this.handleInputChange}
									>
										<option value="">
											{strings.pickYear}
										</option>
										<option value="0">
											{strings.lastYearBeforeSchool}
										</option>
										<option value="1">
											{strings.formatString(
												strings.classNum,
												1
											)}
										</option>
									</select>
								</label>
							)}
							{this.state.education === 'barneskole' && (
								<label>
									{strings.class}
									<select
										
										name="class"
										onChange={this.handleInputChange}
									>
										<option value="">
											{strings.class}
										</option>
										{/* <option value="1">
											{strings.formatString(
												strings.classNum,
												1
											)}
										</option> */}
										<option value="2">
											{strings.formatString(
												strings.classNum,
												2
											)}
										</option>
										<option value="3">
											{strings.formatString(
												strings.classNum,
												3
											)}
										</option>
										<option value="4">
											{strings.formatString(
												strings.classNum,
												4
											)}
										</option>
										<option value="5">
											{strings.formatString(
												strings.classNum,
												5
											)}
										</option>
										<option value="6">
											{strings.formatString(
												strings.classNum,
												6
											)}
										</option>
										<option value="7">
											{strings.formatString(
												strings.classNum,
												7
											)}
										</option>
									</select>
								</label>
							)}
							{this.state.education === 'ungdomsskole' && (
								<label>
									{strings.class}
									<select
										
										name="class"
										onChange={this.handleInputChange}
									>
										<option value="">
											{strings.pickClass}
										</option>
										<option value="8">
											{strings.formatString(
												strings.classNum,
												8
											)}
										</option>
										<option value="9">
											{strings.formatString(
												strings.classNum,
												9
											)}
										</option>
										<option value="10">
											{strings.formatString(
												strings.classNum,
												10
											)}
										</option>
									</select>
								</label>
							)}
							{this.state.education === 'videregaendeskole' && (
								<label>
									{strings.class}
									<select
										
										name="class"
										onChange={this.handleInputChange}
									>
										<option value="">
											{strings.pickStudyType}
										</option>
										<option value="studiespesialisering">
											{strings.specialization}
										</option>
										<option value="yrkesfag">
											{strings.vocational}
										</option>
									</select>
								</label>
							)}
							{this.state.education === 'universitet' && (
								<label>
									{strings.class}
									<select
										
										name="class"
										onChange={this.handleInputChange}
									>
										<option value="">
											{strings.pickLevel}
										</option>
										<option value="bachelor">
											{strings.bachelor}
										</option>
										<option value="master">
											{strings.master}
										</option>
										<option value="phd">
											{strings.phd}
										</option>
									</select>
								</label>
							)}
							{this.state.education === 'other' && (
								<label>
									{strings.specify}
									<input

										type="text"
										name="educationOtherExplain"
										onChange={this.handleInputChange}
									/>
								</label>
							)}
						</Fragment>
						<h2>{strings.other}</h2>

								{!this.state.allowBilingualSelect && (
									<label>
									{strings.bilinguagualHomeQuestion}
									<input
									type="text"
									name="languagesInHome"
									placeholder={this.languageStatus(candidateInfo.languagesInHome)}
								
									onFocus={(e) => {this.setState({
										allowBilingualSelect:true
									})}}
									onBlur={(e) => {
										this.setState({
											allowBilingualSelect:false
										})
									}}
								
								/>
									</label>
								)}
						{this.state.allowBilingualSelect && (
							<label>
							{strings.bilinguagualHomeQuestion}
							<select
								name="languagesInHome"
							
								onChange={this.handleInputChange}
							>
								<option value="">{strings.pick}</option>
								<option value="one">
									{strings.unilingual}
								</option>
								<option value="two">{strings.bilingual}</option>
								<option value="multiple">
									{strings.multilingual}
								</option>
							</select>
						</label>
						)}
						<label>
							<input
								type="checkbox"
								name="agreedToPrivacyPolicy"
								required
							/>{' '}
							{strings.iConsent}
							<br />
							{strings.consentText}{' '}
							<a  style={{color:'cyan'}}
								target="_blank"
								rel="noopener noreferrer"
								href="https://literate.no/personvernerklaering/"
							>
								{strings.literatePrivacyPolicy}
							</a>
						</label>
						<AdminReadyButton
							remoteIsReady={isClientReady}
							// onClick={this.handleReadyClick}
						/>
					</form>
				</div>
			</div>
		);
	}
}

export default TestIntroFilled;
