/* eslint-disable no-lone-blocks */
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {
	updateStage,
	requestRoomKey,
	subscribeToRoomConfig,
	updateStepData,
	updateLanguage,
	submitTestResults,
	submitCandidateInfo,
	startTimer,
	endTimer,
	pingServer,
	pingClient,
	subscribeToIdentityProvision,
	subscribeToClientPong,
	subscribeToMasterPing,
	subscribeToServerPong,
	masterPong,
} from 'api';
import {IS_NORMING_VARIANT, ENVIRONMENT} from 'constants/index';
import {tests} from 'constants/tests';

import SpoonerismAdmin from 'tests/Spoonerism/Admin';
import LetterKnowledgeAdmin from 'tests/LetterKnowledge/Admin';
import WorkingMemoryAdmin from 'tests/WorkingMemory/Admin';
import OneMinuteAdmin from 'tests/OneMinute/Admin';
import RapidNamingAdmin from 'tests/RapidNaming/Admin';
import PhonemeIsolationAdmin from 'tests/PhonemeIsolation/Admin';
import PhonemeDeletionAdmin from 'tests/PhonemeDeletion/Admin';
import WordDictationAdmin from 'tests/WordDictation/Admin';
import TestComplete from 'components/TestComplete';

import updateLogEvent from '../../endpoints/updateLogEvent';
import getTestKeyInfo from '../../endpoints/getTestKeyInfo';

import {getNameFromNumber, getTestsForClass} from 'utils/testUtils';

import {createResults} from 'utils/resultsUtils';
import queryString from 'query-string';
import TestIntro from 'components/TestIntro';
import TestIntroFilled from 'components/TestIntroFilled';
import ConnectionStatus from 'components/ConnectionStatus';
import ProgressIndication from 'components/ProgressIndication';
import SubtestUseStatus from 'components/SubtestUseStatus';
import './styles.scss';
import strings from 'strings';
const parsedSearch = queryString.parse(window.location.search);
let search = parsedSearch;
		let params = new URLSearchParams(search);
		let token = params.get('token');
const getTestFromName = (testName) => {
	switch (testName) {
		case 'Spoonerism':
			return SpoonerismAdmin;
		case 'LetterKnowledge':
			return LetterKnowledgeAdmin;
		case 'WorkingMemory':
			return WorkingMemoryAdmin;
		case 'RapidNaming':
			return RapidNamingAdmin;
		case 'PhonemeDeletion':
			return PhonemeDeletionAdmin;
		case 'PhonemeIsolation':
			return PhonemeIsolationAdmin;
		case 'OneMinute':
			return OneMinuteAdmin;
		case 'WordDictation':
			return WordDictationAdmin;
		case 'TestComplete':
			return TestComplete;
		default:
			return null;
	}
};

class TestAdmin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			roomConfig: null,
			userInformation:null,
			subTestsTaken:null,
			test: 0,
			step: 0,
			stage: 0,
			lastServerPong: moment().subtract(10, 'second'),
			lastClientPong: moment().subtract(10, 'second'),
		};
	}

	componentDidMount() {
		requestRoomKey(this.props.testKey, token);
		subscribeToIdentityProvision(() => {
			requestRoomKey(this.props.testKey, token);
		});
		subscribeToRoomConfig((roomConfig) => {
			if (ENVIRONMENT !== 'production')
				console.log('roomconfig', roomConfig);
			this.setState({
				roomConfig: roomConfig,
				test: roomConfig.currentTest,
				stage: roomConfig.currentStage,
				step: roomConfig.currentStep,
				candiateReady: roomConfig.candidateReady,
				stepData: roomConfig.stepData,
			});
		});
		if(this.props.testContinued){
			getTestKeyInfo(this.props.testKey).then((results)=>{
			 this.setState({
				   subTestsTaken:results.subTestsTaken,
				userInformation:results.userInfo
			   })
			   
		   })
	   }
		this.startConnectionMonitoring();
		
	}

	startConnectionMonitoring = () => {
		subscribeToServerPong((payload) => {
			this.setState({
				lastServerPong: moment(),
			});
		});
		subscribeToClientPong((payload) => {
			this.setState({
				lastClientPong: moment(),
			});
		});
		subscribeToMasterPing((payload) => {
			masterPong(payload);
		});

		pingServer();
		pingClient();
		this.serverPingInterval = setInterval(pingServer, 5000);
		this.clientPingInterval = setInterval(pingClient, 5000);
	};

	nextStep = (results) => {
		const {step, stage, test, roomConfig} = this.state;
		//if type === 'child'
		const testName = getNameFromNumber(
			test,
			roomConfig.ageGroup,
			this.props.testKeyType
		);

		let RapidCurrentTest;
		if (testName === 'RapidNaming') {
			RapidCurrentTest = tests[testName](roomConfig.ageGroup);
		} else if (
			testName === 'LetterKnowledge' ||
			testName === 'PhonemeIsolation'
		) {
			RapidCurrentTest = tests[testName](
				this.props.testKeyType,
				roomConfig.ageGroup
			);
		} else {
			RapidCurrentTest = tests[testName]();
		}
		const currentTest = RapidCurrentTest;

		let stepCount;

		if (testName === 'WorkingMemory') {
			if (stage === 0) stepCount = currentTest.getIntroSteps().length;
			else if (stage === 1)
				stepCount = currentTest.getNumberTrialSteps().length;
			else if (stage === 2) stepCount = 0;
			else if (stage === 3)
				stepCount = currentTest.getNumberSteps().length;
			else if (stage === 4) stepCount = 0;
			else if (stage === 5)
				stepCount = currentTest.getIntroSteps().length;
			else if (stage === 6)
				stepCount = currentTest.getWordTrialSteps().length;
			else if (stage === 7) stepCount = 0;
			else if (stage === 8) stepCount = currentTest.getWordSteps().length;
			else if (stage === 9) {
				stepCount = 0;
			}
		} else {
			if (stage === 0) stepCount = currentTest.getIntroSteps().length;
			else if (stage === 1) stepCount = currentTest.getSteps().length;
			else if (stage === 2) stepCount = 0;
		}

		if (step >= stepCount - 1) {
			this.nextStage(results);
		} else {
			const newStep = step + 1;
			this.setState({
				step: newStep,
			});
			updateStage(test, stage, newStep);
		}
	};

	nextStage = (answers) => {
		const {stage, test, roomConfig} = this.state;

		const vars = {};

		const testName = getNameFromNumber(
			test,
			roomConfig.ageGroup,
			this.props.testKeyType
		);
		const currentTest = tests[testName]();
		if (currentTest.stages[stage] === 'Intro') {
			vars.startTime = new Date();
		} else if (
			currentTest.stages[stage] === 'Test' ||
			currentTest.stages[stage] === 'words'
		) {
			vars.endTime = new Date();
			if (answers) this.submitResults(answers, vars);
		}
		if (stage >= currentTest.stages.length - 1) {
			// This is last step
			this.nextTest();
		} else {
			//Not last step, increment stage
			const newStage = stage + 1;
			this.setState({
				step: 0,
				stage: newStage,
			});
			updateStage(test, newStage, 0, vars);
		}
	};
	nextTest = () => {
		const {test} = this.state;
		if (test > tests.length - 1) {
		} else {
			const newTest = test + 1;
			this.setState({
				test: newTest,
				step: 0,
				stage: 0,
			});
			updateStage(newTest, 0, 0);
		}
	};

	submitResults = (answers, vars = null, timers = null) => {
		const {roomConfig} = this.state;
		const token = localStorage.getItem('authtoken-child');
		const {testId, results} = createResults(
			roomConfig,
			answers,
			vars,
			timers,
			this.props.testKeyType
		);

		submitTestResults(testId, results, token);
	};

	updateStepData = (stepData) => {
		updateStepData(stepData);
	};
	updateLanguage = (lang) => {
		updateLanguage(lang);
	};

	submitCandidateInfo = (candidateInfo, continued) => {
		const {roomConfig} = this.state;
		const token = localStorage.getItem('authtoken-child');
		const info = {
			...candidateInfo,
			adminId:
				this.props.testKeyType === 'youth-followup' ||
				this.props.testKeyType === 'adult-followup' ||
				this.props.testKeyType === 'child-followup'
					? this.props.adminId
					: roomConfig.candidateInfo.adminId,
		};
		submitCandidateInfo(roomConfig.key, info, token, continued);
	};

	goToTest = (e, test) => {
		if (e) e.preventDefault();
		const {roomConfig} = this.state;
		
		updateLogEvent('jump',roomConfig.key).then((res)=>{

			updateStage(Number(test), 0, 0);
		})

		
	};
	startTimer = () => {
		const {test, stage, step} = this.state;
		startTimer(test, stage, step);
	};
	endTimer = () => {
		const {test, stage, step} = this.state;
		endTimer(test, stage, step);
	};

	onTestIntroComplete = (candidateInfo) => {
		const myInfo = {...candidateInfo, ageGroup: candidateInfo.class};
		this.submitCandidateInfo(myInfo, Boolean(this.state.userInformation || this.props.testContinued));

		this.setState({
			ageGroup: myInfo.ageGroup,
			test: 1,
		});
		updateStage(1, 0, 0);
	};
	returnProperTestIntro = (isValidTest,roomConfig) =>{
		let TestIntorToReturn= null;
		{(!this.state.userInformation || !this.props.testContinued) && !isValidTest &&  (
			TestIntorToReturn = (<TestIntro
				roomKey={roomConfig.key}
				isClientReady={roomConfig.testUserId}
				onReadyClick={this.onTestIntroComplete}
				testKeyType={this.props.testKeyType}
				updateCandidateInfo={this.updateCandidateInfo}
				updateLanguage={this.updateLanguage}
				userInformation={this.state.userInformation}
				subTestsTaken= {this.state.subTestsTaken}
			/>)
		)}
		{!isValidTest && (this.state.userInformation || this.props.testContinued) && (
			TestIntorToReturn=	(<TestIntroFilled
				roomKey={roomConfig.key}
				isClientReady={roomConfig.testUserId}
				onReadyClick={this.onTestIntroComplete}
				testKeyType={this.props.testKeyType}
				updateCandidateInfo={this.updateCandidateInfo}
				updateLanguage={this.updateLanguage}
				userInformation={this.state.userInformation}
				subTestsTaken= {this.state.subTestsTaken}
			/>)
		)}
		return TestIntorToReturn;
	}
	render() {
		const {
			roomConfig,
			ageGroup,
			lastServerPong,
			lastClientPong,
		} = this.state;

		let CurrentTestAdminPage = null;
		let currentSubTest = null;

		const isValidTest = Boolean(
			roomConfig && roomConfig.currentTest !== null
		);

		let testNumbers = {};
		if (isValidTest) {
			const testNumber = roomConfig.currentTest;
			const testName = getNameFromNumber(
				testNumber,
				ageGroup,
				this.props.testKeyType
			);
			currentSubTest = testName;
			CurrentTestAdminPage = getTestFromName(testName);

			testNumbers = getTestsForClass(ageGroup, this.props.testKeyType);
		}
		const testNumbersArray = Object.entries(testNumbers);

		return (
			<div className="TestAdmin" onKeyDown={this.handleKeyPress}>
				{roomConfig && isValidTest && (
					<ProgressIndication
						tests={testNumbersArray}
						subTestsTaken={this.state.subTestsTaken}
						roomConfig={roomConfig}
					/>
				)}
				{roomConfig && isValidTest && this.state.subTestsTaken && (
					<SubtestUseStatus
						tests={testNumbersArray}
						subTestsTaken={this.state.subTestsTaken}
						roomConfig={roomConfig}
						currentTestName={currentSubTest}
						
					/>
				)}
				<ConnectionStatus
					lastServerPong={lastServerPong}
					lastClientPong={lastClientPong}
				/>
				<div className="TestAdmin__header">
					<h1>{strings.testadministrator}</h1>
					<div className="center">
						{isValidTest && (
							<form
								onSubmit={(e) =>
									this.goToTest(e, this.testSelect.value)
								}
								className="GoToTestForm"
							>
								<p>
									<select
										ref={(node) => (this.testSelect = node)}
									>
										{testNumbersArray.map(
											([key, value]) => (
												<option value={key} key={key}>
													{
														strings[
															'testName_' + value
														]
													}
												</option>
											)
										)}
									</select>
									<button type="submit">
										{strings.jumptotest}
									</button>
								</p>
							</form>
						)}
					</div>
				</div>
				{roomConfig && (
					<Fragment>
						<p className="roomkey">
							{strings.formatString(
								strings.roomkey,
								<b>{roomConfig.key}</b>
							)}
						</p>
						{this.returnProperTestIntro(isValidTest,roomConfig)}
						{isValidTest && (
							<CurrentTestAdminPage
								currentStage={roomConfig.currentStage}
								currentStep={roomConfig.currentStep}
								stepData={roomConfig.stepData}
								candidateReady={roomConfig.candidateReady}
								ageGroup={roomConfig.ageGroup}
								startTimer={this.startTimer}
								endTimer={this.endTimer}
								nextStep={this.nextStep}
								nextStage={this.nextStage}
								nextTest={this.nextTest}
								testKeyType={this.props.testKeyType}
								submitResults={this.submitResults}
								updateStepData={this.updateStepData}
								subTestsTaken={this.state.subTestsTaken}
							/>
						)}
					</Fragment>
				)}
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	const {user, token} = state.auth;

	return {
		user,
		token,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TestAdmin);
