import {Howl} from 'howler';

const imageNames = [
	'boat',
	'baby',
	'ball',
	'teddy',
	'banana',
	'flower',
	'book',
	'fireengine',
	'plowtruck',
	'jeans',
	'doll',
	'elephant',
	'apple',
	'finger',
	'fish',
	'plane',
	'foot',
	'sweater',
	'porrige',
	'horse',
	'house',
	'icecream',
	'rabbit',
	'cat',
	'cup',
	'crocodile',
	'cow',
	'lion',
	'motorcycle',
	'nose',
	'key',
	'package',
	'reflex',
	'saft',
	'bed',
	'ski',
	'spoon',
	'shoe',
	'sun',
	'chair',
	'boot',
	'bicycle',
	'telephone',
	'tractor',
	'purse',
	'gloves',
	'ear',
	'eye',
];

export const images = {};

imageNames.forEach((imageName) => {
	images[imageName] = require('../../assets/images/' + imageName + '.png');
});
export const soundNames = [
	'starts_with_b',
	'starts_with_e',
	'starts_with_g',
	'starts_with_h',
	'starts_with_i',
	'starts_with_k',
	'starts_with_l',
	'starts_with_oe',
	'starts_with_s',
	'starts_with_v',
	'ends_with_e',
	'ends_with_i',
	'ends_with_k',
	'ends_with_n',
	'ends_with_o',
	'ends_with_p',
	'ends_with_r',
	'ends_with_t',
	'ends_with_u',
	'ends_with_y',
];

export const getSounds = (language) => {
	const sounds = {};

	soundNames.forEach((soundName) => {
		const sound = require(`../../assets/sounds/${language}/phonemeIsolation/${soundName}.mp3`);
		sounds[soundName] = new Howl({
			src: sound,
		});
	});

	return sounds;
};
