import React, {Component} from 'react';
import moment from 'moment';

import {getNameFromNumber} from 'utils/testUtils';
import {createResults} from 'utils/resultsUtils';

import {
	updateStage,
	updateReady,
	subscribeToRoomConfig,
	joinRoom,
	submitTestResults,
	startTimer,
	endTimer,
	subscribeToClientPing,
	subscribeToMasterPong,
	subscribeToServerPong,
	clientPong,
} from 'api';

import WorkingMemory from 'tests/WorkingMemory';
import RapidNaming from 'tests/RapidNaming';
import PhonemeIsolation from 'tests/PhonemeIsolation';
import PhonemeDeletion from 'tests/PhonemeDeletion';
import OneMinute from 'tests/OneMinute';
import LetterKnowledge from 'tests/LetterKnowledge';
import WordDictation from 'tests/WordDictation';
import Spoonerism from 'tests/Spoonerism';
import TestComplete from 'components/TestComplete';

import {tests} from 'constants/tests.js';

import './styles.scss';
import strings from 'strings';

const getTestFromName = (testName) => {
	switch (testName) {
		case 'LetterKnowledge':
			return LetterKnowledge;
		case 'Spoonerism':
			return Spoonerism;
		case 'WorkingMemory':
			return WorkingMemory;
		case 'RapidNaming':
			return RapidNaming;
		case 'PhonemeDeletion':
			return PhonemeDeletion;
		case 'PhonemeIsolation':
			return PhonemeIsolation;
		case 'OneMinute':
			return OneMinute;
		case 'WordDictation':
			return WordDictation;
		default:
			return TestComplete;
	}
};

class TestUser extends Component {
	constructor(props) {
		super(props);

		this.state = {
			roomConfig: {},
			currentStep: 0,
			currentStage: 0,
			currentTest: 0,
		};
	}

	componentDidMount() {
		subscribeToRoomConfig((roomConfig) => {
			if (
				roomConfig.language &&
				roomConfig.language !== strings.getLanguage()
			) {
				strings.setLanguage(roomConfig.language);
			}

			this.setState({
				roomConfig: roomConfig,
				currentTest: roomConfig.currentTest || 0,
				currentStage: roomConfig.currentStage || 0,
				currentStep: roomConfig.currentStep || 0,
				stepData: roomConfig.stepData,
			});
		});

		subscribeToServerPong((payload) => {
			this.setState({
				lastServerPong: moment(),
			});
		});
		subscribeToMasterPong((payload) => {
			this.setState({
				lastClientPong: moment(),
			});
		});
		subscribeToClientPing((payload) => {
			clientPong(payload);
		});

		joinRoom(this.props.roomKey)
			.then(() => {})
			.catch(() => this.props.onSelectRole('initial'));
	}

	nextTest = (results) => {
		const {currentTest} = this.state;
		if (results) this.submitResults(results);

		if (currentTest > tests.length - 1) {
		} else {
			const newTest = currentTest + 1;
			this.setState({
				currentTest: newTest,
				currentStep: 0,
				currentStage: 0,
			});
			updateStage(newTest, 0, 0);
		}
	};

	nextStep = () => {
		const {
			currentStep: step,
			currentStage: stage,
			currentTest: test,
		} = this.state;

		const newStep = step + 1;
		this.setState({
			step: newStep,
		});
		endTimer(test, stage, newStep);
		updateStage(test, stage, newStep);
	};

	setReady = (isReady) => {
		updateReady(isReady);
	};

	submitResults = (answers, vars = null, timers = null) => {
		const {roomConfig} = this.state;

		const {testId, results} = createResults(
			roomConfig,
			answers,
			vars,
			timers
		);

		if (
			['youth-followup','adult-followup','child-followup'].includes(this.props.testKeyType)
		)
			submitTestResults(testId, results);
		else
			submitTestResults(
				testId,
				results,
				roomConfig.candidateInfo.adminId
			);
	};

	submitClientResults = (answers) => {
		const {currentTest} = this.state;
		updateStage(currentTest, 2, 0);
		this.submitResults(answers);
	};
	startTimer = () => {
		const {
			currentTest: test,
			currentStage: stage,
			currentStep: step,
		} = this.state;
		startTimer(test, stage, step);
	};
	endTimer = () => {
		const {
			currentTest: test,
			currentStage: stage,
			currentStep: step,
		} = this.state;
		endTimer(test, stage, step);
	};
	render() {
		const {roomConfig, currentTest} = this.state;

		let StageComponent = null;
		let isTest = false;

		if (roomConfig && currentTest !== 0 && roomConfig.ageGroup) {
			const testName = getNameFromNumber(
				currentTest,
				roomConfig.ageGroup,
				roomConfig.candidateInfo.testType
			);
			const test = getTestFromName(testName);
			isTest = Boolean(test);
			if (isTest) {
				StageComponent = test;
			}
		}

		

		return (
			<div className="TestUser">
				<p className="testKey">{this.props.roomKey}</p>
				{(!isTest || currentTest === 0) && (
					<div className="TestUserIntro">
						<p>{strings.waitforadminstart}</p>
					</div>
				)}
				{isTest && currentTest !== 0 && (
					<div className="test">
						<StageComponent
							soundPool={this.props.soundPool}
							currentTest={roomConfig.currentTest}
							currentStage={roomConfig.currentStage}
							currentStep={roomConfig.currentStep}
							stepData={roomConfig.stepData}
							nextStep={this.nextStep}
							ageGroup={roomConfig.ageGroup}
							startTimer={this.startTimer}
							endTimer={this.endTimer}
							testKeyType={this.props.testKeyType}
							submitWordDictation={this.submitClientResults}
							nextTest={this.nextTest}
							submitPhonemeIsolation={this.submitClientResults}
							setReady={this.setReady}
							isCandidate={true}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default TestUser;
