import {initAuth} from 'utils/bankIdLogin';

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const setAuthTokenAction = (token) => {
	return (dispatch) => {
		localStorage.setItem('authtoken-child', token);

		dispatch({
			type: SET_AUTH_TOKEN,
			token: token,
			isAuthenticated: true,
		});
	};
};

export const INIT_AUTH = 'INIT_AUTH';
export const RESET_AUTH = 'RESET_AUTH';
export const SET_AUTH = 'SET_AUTH';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_AUTH_IFRAME_URL = 'SET_AUTH_IFRAME_URL';
export const initAuthAction = (bankIdType, key) => {
	console.log('initAuthAction');
	return (dispatch) => {
		dispatch({
			type: RESET_AUTH,
		});
		dispatch({
			type: INIT_AUTH,
		});

		initAuth(bankIdType, key)
			.then((responseJson) => {
				dispatch({
					type: SET_AUTH_IFRAME_URL,
					iframeUrl: responseJson.redirect,
				});
				const listener = window.addEventListener('message', (event) => {
					let data;
					try {
						/* console.log('event.data', event.data); */
						data = event.data ? JSON.parse(event.data) : null;
					} catch (e) {
						console.log(e);
						return;
					}
					if (
						data &&
						data.action === 'auth' &&
						data.result === 'OK'
					) {
						window.removeEventListener('message', listener);

						localStorage.setItem('authtoken-child', data.authToken);

						dispatch({
							type: SET_AUTH,
							user: data.userData,
							token: data.authToken,
							isAuthenticated: true,
						});
					}
				});
			})
			.catch((error) => {
				dispatch({
					type: SET_AUTH_ERROR,
					error,
				});
			});
	};
};
