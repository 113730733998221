import React from 'react';
import strings from 'strings';

import {getWordDictation} from 'constants/tests.js';

import AdminTestPanel from 'components/AdminTestPanel';
import AdminReadyButton from 'components/AdminReadyButton';

const options = {
	enableHotkeys: false,
	enableTimers: false,
};

class WordDictationAdmin extends AdminTestPanel {
	constructor(props) {
		super(props, options);
	}

	getTestName = () => {
		return strings.wd_testname;
	};

	renderIntro = () => {
		return (
			<div>
				<AdminReadyButton
					remoteIsReady={this.props.candidateReady}
					onClick={() => {
						this.props.nextStage();
						this.props.startTimer();
					}}
				/>
			</div>
		);
	};

	renderTest = () => {
		const {currentStep} = this.state;
		const steps = getWordDictation(this.props.testKeyType).getSteps();
		return (
			<div>
				{currentStep < steps.length && (
					<p>{strings.candidateAnswersSelf}</p>
				)}
				{currentStep >= steps.length && (
					<AdminReadyButton
						remoteIsReady={this.props.candidateReady}
						onClick={() => {
							this.props.nextStage();
							this.props.startTimer();
						}}
					/>
				)}
			</div>
		);
	};

	renderWaiting = () => {
		return (
			<div>
				<p>
					{strings.formatString(
						strings.testDone,
						strings.wd_testname
					)}
				</p>
				<button onClick={() => this.props.nextStage()}>
					{strings.nextTest}
				</button>
			</div>
		);
	};
}

export default WordDictationAdmin;
